import React from 'react';
import { clioManageMatterLink } from '~/src/utils/clio';
import analyticsService from '~/src/services/analytics';
import { Instance } from 'mobx-state-tree';
import { Matter } from '~/src/stores/matterStore';
import SyncSingleMatterCard from './SyncSingleMatterCard';
import { useSyncSingleMatterModal } from './useSyncSingleMatterModal';

import { useIsClioAuthenticated } from '~/src/hooks/useClio';
import useFeatureFlags from '~/src/hooks/useFeatureFlags';

type SingleMatterSyncProps = {
  matter: Instance<typeof Matter>;
  onSynced?: () => void;
  analyticsEventName: string;
};

const SingleMatterSync = ({
  matter,
  onSynced,
  analyticsEventName,
}: SingleMatterSyncProps) => {
  const isAuthenticatedWithClio = useIsClioAuthenticated();
  const isUnifiedPlatform = useFeatureFlags().isUnifiedPlatform;
  const [{ sync }, modal] = useSyncSingleMatterModal(
    analyticsEventName,
    onSynced,
    {
      onMutate: ({ clio_matter_id }) => {
        analyticsService.track(
          `Single Matter Sync from ${analyticsEventName} - Initiated`,
          {
            clioMatterId: clio_matter_id,
          },
        );
      },
    },
  );

  const clioMatterId = matter.externalMatterId;
  if (!clioMatterId || !isAuthenticatedWithClio || !isUnifiedPlatform)
    return null;

  return (
    <>
      <SyncSingleMatterCard
        lastUpdated={matter.lastSynced ? matter.lastSynced : undefined}
        handleSync={() => sync(clioMatterId)}
        linkToManage={clioManageMatterLink(+clioMatterId)}
      />
      {modal}
    </>
  );
};

export default SingleMatterSync;
