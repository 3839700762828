export default {
  sentryCommitSha:
    process.env.REACT_APP_COMMIT_SHA || process.env.CF_PAGES_COMMIT_SHA,
  sentryEnvironment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV,
  basename: process.env.REACT_APP_ROUTE_BASENAME || '/clients',
  apiUrl: window.location.origin,
  assetUrl: process.env.CF_PAGES_URL,
  clioBaseUrl: process.env.REACT_APP_CLIO_MANAGE_BASE_URL,
  clioClientId: process.env.REACT_APP_CLIO_MANAGE_CLIENT_ID,
  clioQuestionnaireBaseUrl:
    process.env.REACT_APP_CLIO_QUESTIONNAIRE_BASE_URL || '/questionnaires',
};
