import { http, getPaginationUrlQuery } from '~/src/services/requests';

function matterService() {
  return {
    createNewMatter: async (orgFprint, title) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/matter/`,
          method: 'POST',
          body: {
            title,
          },
        },
        parse,
      );
    },
    updateMatter: async (orgFprint, matterId, propertiesToUpdate) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/matter/${matterId}/`,
          method: 'PUT',
          body: propertiesToUpdate,
        },
        parse,
      );
    },
    deleteMatter: async (orgFprint, matterId) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/matter/${matterId}/`,
          method: 'DELETE',
        },
        parse,
      );
    },
    fetchMatter: async (orgFprint, matterId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/matter/${matterId}/`,
          method: 'GET',
        },
        parse,
      );
    },
    fetchMatterQuestionnaire: async (orgFprint, matterId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/matter/${matterId}}/recipient/ `,
          method: 'GET',
        },
        parse,
      );
    },
    getMatterDetail: async (orgFprint, matterId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/matter/${matterId}/`,
          method: 'GET',
        },
        parse,
      );
    },
    getMatters: async (orgFprint, query) => {
      let searchQuery = '';

      if (query) {
        const { page, search, sortBy, order, limit } = query;
        searchQuery = getPaginationUrlQuery({
          page,
          search,
          sortBy,
          order,
          limit,
        });
      }

      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/matter/${searchQuery}`,
          method: 'GET',
        },
        parse,
      );
    },
    getDocument: async (orgFprint, documentId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/project/${documentId}`,
          method: 'GET',
        },
        parse,
      );
    },
  };
}

export default matterService();
