import React from 'react';
import moment from 'moment';
import { css } from 'aphrodite';
import { throttle } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Error } from '~/src/components/Type';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import Banner from '~/src/components/Banner';
import Button from '~/src/components/Button';
import { Label } from '~/src/components/Inputs';
import { Form, renderFormField } from '~/src/components/Forms';

/* Styles */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import styles from './styles';
import { canOrgUseFeatureFlag, getOrg } from '~/src/entities/user';

/* Constants */

const MIN_NUMBER_OF_SEATS = 1;

class SubscriptionUpdateModal extends React.Component {
  state = {
    total: 0,
    discount: 0,
    loading: false,
    showSeatsFilledNotification: false,
    seats: 1,
  };

  handleUpdateSeats = async () => {
    const { subscription } = this.props.store.user.currentOrganization;
    const { quantity } = subscription;
    const {
      plan: { interval },
    } = subscription;
    const { seats } = this.state;
    const duration = interval === 'month' ? 'monthly' : 'annual';
    const additionalSeats = seats - quantity;

    if (additionalSeats !== 0) {
      this.setState({ loading: true });

      const res =
        await this.props.store.user.currentOrganization.updateSubscription({
          seat: seats,
          duration,
        });

      this.setState({ loading: false });

      if (res) {
        this.props.showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Subscription updated',
        });
        this.props.onCancel();
      } else {
        this.props.showToast(LAYOUT_TOAST_TYPES.error);
      }
    } else {
      this.props.handleCancel();
    }
  };

  componentDidMount() {
    const memberCount =
      this.props.store.user.currentOrganization.members.length;
    this.setState({ seats: memberCount + 1 });
  }

  handleFormChange = throttle(async (form) => {
    const seats = parseInt(form.fields.seats.value);
    const memberCount =
      this.props.store.user.currentOrganization.members.length;
    this.setState({
      seats,
      showSeatsFilledNotification: memberCount > seats,
    });
    const res =
      await this.props.store.user.currentOrganization.fetchSeatInvoice(seats);
    if (res) {
      const { total } = res;
      const discount =
        (res.discount &&
          res.discount.coupon &&
          res.discount.coupon.percent_off) ||
        0;

      this.setState({ total, discount });
    }
  }, 500);

  handleCancel = () => {
    this.props.onCancel();
  };

  getError = () => {
    const { seats, showSeatsFilledNotification } = this.state;

    if (seats < MIN_NUMBER_OF_SEATS) {
      return '*Must have at least 1 seat';
    }

    if (showSeatsFilledNotification) {
      return '*Occupied seats cannot be removed. You need to remove users from your organization first.';
    }

    return null;
  };

  disabled = () => {
    const memberCount =
      this.props.store.user.currentOrganization.members.length;
    const { quantity } = this.props.store.user.currentOrganization.subscription;

    const { seats, loading } = this.state;

    const additionalSeats = seats - quantity;

    return (
      seats < MIN_NUMBER_OF_SEATS ||
      seats < memberCount ||
      additionalSeats === 0 ||
      loading
    );
  };

  getAdditionalSeatsAvailable() {
    const { subscription } = this.props.store.user.currentOrganization;
    const { quantity } = subscription;

    const { seats } = this.state;

    const additionalSeats = seats - quantity;

    return additionalSeats;
  }

  getButtonLabel = () => {
    const { loading } = this.state;
    const additionalSeats = this.getAdditionalSeatsAvailable();

    const numberOfSeatsLabel = `${Math.abs(additionalSeats)} ${
      additionalSeats === 1 ? 'seat' : 'seats'
    }`;
    const addRemoveLabel = additionalSeats >= 0 ? 'Add' : 'Remove';

    const buttonLabel = `${addRemoveLabel} ${numberOfSeatsLabel}`;

    return loading ? 'Saving...' : buttonLabel;
  };

  render() {
    const isV2Subscriptions = canOrgUseFeatureFlag(
      'SUBSCRIPTIONS_V2',
      getOrg(),
    );
    const memberCount =
      this.props.store.user.currentOrganization.members.length;
    const { subscription } = this.props.store.user.currentOrganization;
    const { quantity } = subscription;

    const { seats, loading, discount } = this.state;

    const seatLabel = seats === 1 ? 'seat' : 'seats';

    const fields = [
      {
        id: 'seats',
        label: 'Number of seats',
        type: 'incrementer',
        minValue: memberCount,
        maxValue: 1000,
        defaultValue: quantity,
        validation: () => true,
      },
    ];

    const planPrice =
      this.props.store.user.currentOrganization.subscription.plan.amount;
    const { upcomingSeatPrice } = this.props.store.user.currentOrganization;
    const discountedPlanPrice = planPrice - planPrice * (discount / 100);
    const discountedMonthlyPrice = discountedPlanPrice * (seats - quantity);

    const buttonLabel = this.getButtonLabel();

    const dayInMonth = moment().date();
    const daysInMonth = moment().daysInMonth();
    const pricePerDay = Math.round(discountedMonthlyPrice / daysInMonth);
    const proRatedPrice =
      dayInMonth * pricePerDay < 0 ? 0 : dayInMonth * pricePerDay;

    const totalPrice = ((planPrice * seats) / 100).toFixed(2);
    const totalDiscountedPrice = ((discountedPlanPrice * seats) / 100).toFixed(
      2,
    );
    const dueToday = isV2Subscriptions
      ? (upcomingSeatPrice / 100).toFixed(2)
      : (proRatedPrice / 100).toFixed(2);

    const customerCredit = (upcomingSeatPrice / 100).toFixed(2) <= 0;
    const totalDueToday =
      seats > quantity && !customerCredit && dueToday < planPrice
        ? dueToday
        : 0;

    const renderFields = fields.map((field) => renderFormField(field));

    const error = this.getError();
    const disabled = this.disabled();

    return (
      <div
        className={css(styles.modalContainer)}
        style={{ minWidth: '1200px', minHeight: '300px', width: '800px' }}
      >
        <h1 className={css(styles.modalTitle)}>Update seats</h1>
        <div className={css(styles.modalBody)}>
          <Form
            triggerOnMount
            fields={renderFields}
            onChange={this.handleFormChange}
          />
          <Error cssStyle={styles.modalRowSmall}>{error}</Error>
          {!isV2Subscriptions && (
            <div>
              <div className={css(styles.modalRow)}>
                <Label label={'New plan total'}>
                  ${totalPrice} per month for {seats} {seatLabel}
                </Label>
                {discount > 0 && (
                  <Label label={'Discounted plan total'}>
                    ${totalDiscountedPrice} per month for {seats} {seatLabel}
                  </Label>
                )}
              </div>
              <div className={css(styles.modalRow)}>
                <Label label={'Due today'}>${dueToday}</Label>
              </div>
            </div>
          )}
          {!disabled && (
            <div>
              <Banner small>
                <div>
                  You will be charged a prorated amount of{' '}
                  <b>${totalDueToday}</b> for the new seats for the remainder of
                  the current billing period.
                </div>
              </Banner>
            </div>
          )}
        </div>
        <div className={css(styles.modalActions, styles.modalActionsNoMargin)}>
          <Button cssStyle={styles.buttonAction} onClick={this.handleCancel}>
            Cancel
          </Button>{' '}
          <Button
            onClick={this.handleUpdateSeats}
            disabled={disabled}
            loading={loading}
            cssStyle={styles.buttonAction}
            primary
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(SubscriptionUpdateModal)),
);
