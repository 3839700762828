import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useObserver } from 'mobx-react';

/* Hooks */
import useMst, { useStore } from '~/src/hooks/useMst';

import Card from '~/src/components/Card';
import Form, { renderFormField } from '~/src/components/Forms';
import Button from '~/src/components/Button';

import theme from '~/src/theme';
import styles from './styles.js';
import SingleMatterSync from '../SingleMatterSync/SingleMatterSync';
import { useShouldShowMatterSync } from './useShouldShowMatterSync';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';

const multiValueStyles = {
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: theme.colors.pattensBlue,
  }),
};

const TemplateSetDraft = ({
  matter,
  templates,
  sortedSelectedTemplates,
  defaults,
  onChangeTemplate,
  onCreateDraft,
}) => {
  const { matter: matterStore } = useStore();
  const [valid, setValid] = useState(false);
  const [name, setName] = useState(defaults.name);
  const [titleLabel, setTitleLabel] = useState('Document set title');
  const [matterId, setMatterId] = useState(defaults.matterId);
  const [matterLabel, setMatterLabel] = useState('');
  const [disableMatterSelect, setDisableMatterSelect] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [nextButtonLabel, setNextButtonLabel] = useState('Next');

  const selectedMatter = useObserver(() => matterStore.getMatter(matterId));

  const handleChangeTemplate = (field) => {
    // e.g. field.value === ['794'] or ['794', '791'] or ['794', '791', '759']
    setTitleLabel(
      field.value.length === 1 ? 'Document title' : 'Document set title',
    );
    onChangeTemplate(field);
  };

  const { maxTemplateLimit, clioStore } = useMst((store) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => ({
      maxTemplateLimit: store.templateSets.maxTemplateLimit,
      clioStore: store.clio,
    })),
  );

  const onChange = (formState) => {
    setValid(formState.valid);
    setName(formState.fields.title.value);
    if (!disableMatterSelect && formState.fields.matter) {
      setMatterId(formState.fields.matter.value);
    }
  };

  // Called once, when the component is mounted.
  useEffect(() => {
    if (defaults.name) {
      onChange({
        valid: true,
        fields: { title: { value: defaults.name } },
      });
      setValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sortedSelectedTemplates.length >= maxTemplateLimit) {
      multiValueStyles.dropdownIndicator = (provided) => ({
        ...provided,
        display: 'none',
      });
      multiValueStyles.option = (provided) => ({
        ...provided,
        display: 'none',
      });
    } else {
      delete multiValueStyles.dropdownIndicator;
      delete multiValueStyles.option;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedSelectedTemplates]);

  useEffect(() => {
    if (matter.length > 0 && clioStore && clioStore.syncedMatterId) {
      const givenMatter = matter.find(
        (m) => m.value === clioStore.syncedMatterId?.toString(),
      );

      if (givenMatter) {
        setMatterId(givenMatter.value);
        setMatterLabel(givenMatter.label);
        setDisableMatterSelect(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matter]);

  const handleCreateDraft = () => {
    setNextButtonDisabled(true);
    setNextButtonLabel('Loading...');
    onCreateDraft(name, matterId);
  };

  const sortedSelectedOptions = sortedSelectedTemplates.map(
    (template) => `${template.value}`,
  );

  const removeTemplateDraftingSelectorUIFeatureFlag = useUIFlagEnabled(
    'templateDraftingList',
  );
  const selectedTemplates = {
    label: 'Selected templates',
    id: 'templates',
    type: removeTemplateDraftingSelectorUIFeatureFlag
      ? 'templateList'
      : 'select',
    defaultValue: sortedSelectedOptions,
    options: templates,
    required: true,
    validation: ({ value }) => value && value.length > 0,
    multiple: true,
    onChange: handleChangeTemplate,
    isClearable: true,
    customStyles: multiValueStyles,
    sortedSelectedTemplates: sortedSelectedTemplates,
  };

  const titleField = {
    label: titleLabel,
    id: 'title',
    type: 'text',
    defaultValue: defaults.name,
    required: true,
    validation: ({ value }) => {
      return (value && value.trim().length > 0) === true;
    },
  };

  const matterField = {
    label: 'Matter',
    id: 'matter',
    type: 'staticText',
    defaultValue: matterLabel,
    validation: () => {
      return true;
    },
  };

  const matterSelect = {
    label: 'Choose a matter',
    id: 'matter',
    type: 'matterSelect',
    options: matter,
    required: true,
    validation: ({ value }) => {
      return !!value; // return false if value is undefined, null or ''
    },
  };

  let fields = disableMatterSelect
    ? [selectedTemplates, titleField, matterField]
    : [selectedTemplates, titleField, matterSelect];

  if (removeTemplateDraftingSelectorUIFeatureFlag) {
    // place matter select/text before the title field
    fields = disableMatterSelect
      ? [selectedTemplates, matterField, titleField]
      : [selectedTemplates, matterSelect, titleField];
  }

  const fieldsProp = fields.map((field) => renderFormField(field));

  return (
    <div className={css(styles.templatesDraftContainer)}>
      <Card cssStyle={styles.templatesDraftContainerCard}>
        <Form
          fields={fieldsProp}
          onChange={onChange}
          triggerOnMount={true}
          focusFirstInput={'text'}
          focusTimeout={0}
        />
      </Card>
      {useShouldShowMatterSync(selectedMatter) ? (
        <div className="mt-3">
          <SingleMatterSync
            analyticsEventName="Template Set Draft"
            matter={selectedMatter}
          />
        </div>
      ) : null}
      <div className={css(styles.templatesDraftAction)}>
        <Link to="/templates">
          <Button primary={false}>Cancel</Button>
        </Link>
        <Button
          cssStyle={styles.templatesDraftActionPrimary}
          primary
          disabled={!valid || nextButtonDisabled}
          onClick={handleCreateDraft}
        >
          {nextButtonLabel}
        </Button>
      </div>
    </div>
  );
};

TemplateSetDraft.propTypes = {
  defaults: PropTypes.shape({
    name: PropTypes.string,
    matterId: PropTypes.number,
  }),
  onCreateDraft: PropTypes.func,
};

TemplateSetDraft.defaultProps = {
  defaults: {
    name: '',
    matterId: null,
  },
  onCreateDraft: () => {},
};

export default TemplateSetDraft;
