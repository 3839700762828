import moment from 'moment';
import { Signature } from '@mystacksco/hancock/dist/sign/schema';
import {
  Date,
  Documents as ReadDocuments,
} from '@mystacksco/hancock/dist/read/schema';

import assert from 'assert';
import { WhoSigns, SignaturePackage, InputType } from '~/src/models';

// Represents page numbers for a given element.
interface PageNumbers {
  // The element's page number if all elements are to be merged into one document
  combinedPageNumber: number;
  // The element's page number if the element is to remain in its original document
  separatePageNumber: number;
}

/**
 * There's no special logic to these adjustments. They're just mirroring some logic in the
 * legacy version of Pro X that makes no sense but is unavoidable.
 *
 * For more context, search for these numbers here: https://github.com/mystacksco/lawyaw-pro/blob/ee75f890a70dccb6a6455ad9555b76f05a772438/src/hooks/useBackend.js
 */
const SCALE_FACTOR = 1.34;
const DEFAULT_X = 150;
const DEFAULT_Y = 200;
const calculateMagicOffset = (
  originalCoordinate: number,
  defaultValue: number,
) =>
  originalCoordinate >= 0
    ? (originalCoordinate / SCALE_FACTOR) * 1.015
    : defaultValue;

const isNum = (s: number | undefined | null) => s !== null && !isNaN(Number(s));

/**
 * Takes in a signature package and returns a document or array
 * of documents that Hancock's `Read` class can render
 */
class HancockReadOnlyDocumentMaker {
  private signaturePackage: SignaturePackage.SignaturePackage;

  private documentIdForElements: null | number = null;

  /**
   * Indicates whether this package should be given to Hancock
   * as one combined document, or passed as an array of docs.
   * If we have a generatedCombinedPdf URL, then we need to
   * combine everything into one document with that as the
   * URL. In both cases Hancock will render everything as one
   * document, we just have to do this awkward data-marshalling
   * because a package may or may not have a generatedCombinedPdf.
   */
  private get shouldCombine() {
    return !!this.signaturePackage.generatedCombinedPdf;
  }

  /**
   * If this package was created before the Summer 2022 re-release of e-sign,
   * we have to perform hacky and weird adjustments to the X/Y coordinates of
   * every element in the signature package.
   */
  private get shouldDoMagicAdjustment() {
    return !this.signaturePackage.isUnifiedEsign;
  }

  constructor(signaturePackage: SignaturePackage.SignaturePackage) {
    this.signaturePackage = signaturePackage;
  }

  /**
   * Maps the signature package given to this class into
   * a document or array of documents to give to hancock
   */
  make = (): ReadDocuments =>
    this.shouldCombine ? this.combine() : this.separate();

  /**
   * If we have one combined PDF to render, manually combine the elements
   * across all documents into one Hancock document
   */
  private combine() {
    const { generatedCombinedPdf } = this.signaturePackage;
    assert(!!generatedCombinedPdf, 'Expected to find combined PDF!');
    this.documentIdForElements = null;
    return this.mapToHancockDocument(
      generatedCombinedPdf,
      'combinedPageNumber',
    );
  }

  /**
   * If we have multiple PDFs to render, give Hancock
   * an array of documents
   */
  private separate() {
    return this.signaturePackage.lawyawDocuments.map(
      ({ id, originalFileUrl }) => {
        assert(
          !!originalFileUrl,
          'No file URL found when creating signature package!',
        );
        this.documentIdForElements = id;
        return this.mapToHancockDocument(originalFileUrl, 'separatePageNumber');
      },
    );
  }

  /**
   * Gets all of the signature and date elements out of the package
   * and returns a hancock compatible document.
   */
  private mapToHancockDocument = (
    documentPath: string,
    pageNumberType: 'combinedPageNumber' | 'separatePageNumber',
  ) => {
    const signatures = this.extractRenderableSignatureElements().map(
      ([date, pageNumbers]) =>
        this.mapSignatureElementToHancockSignature(
          date,
          pageNumbers[pageNumberType],
        ),
    );
    const dates = this.extractRenderableDateElements().map(
      ([date, pageNumbers]) =>
        this.mapDateElementToHancockDate(date, pageNumbers[pageNumberType]),
    );
    return { documentPath, signatures, dates };
  };

  /**
   * Gets all of the signature elements out of the package
   */
  private extractRenderableSignatureElements = () => {
    const visibleSignatures: [SignaturePackage.Element, PageNumbers][] = [];
    let combinedPageNumber = 1;
    this.signaturePackage.lawyawDocuments.forEach((document) => {
      document.pages.forEach((page) => {
        page.elements
          .filter(
            (element) =>
              // And it must be a signature
              element.inputType === InputType.SignatureBlock &&
              // ...And it must not already be embedded in the PDF (because then we'd render it twice if we included it here)
              !this.getIsElementAlreadyEmbeddedInPdf(element) &&
              // ...And It must have been submitted by the recipient to be visible
              element.recipient?.submitted === true &&
              (this.documentIdForElements === null ||
                this.documentIdForElements === document.id),
          )
          .forEach((element) => {
            visibleSignatures.push([
              element,
              {
                separatePageNumber: page.pageNumber,
                combinedPageNumber,
              },
            ]);
          });
        combinedPageNumber += 1;
      });
    });

    return visibleSignatures;
  };

  /**
   * Gets all of the date elements out of the package
   */
  private extractRenderableDateElements = () => {
    const visibleDates: [SignaturePackage.Element, PageNumbers][] = [];
    let combinedPageNumber = 1;
    this.signaturePackage.lawyawDocuments.forEach((document) => {
      document.pages.forEach((page) => {
        page.elements
          .filter(
            (element) =>
              // It must be a "date" input or a "textbox" input (we use both of these for dates for some reason)
              [InputType.Textbox, InputType.SignatureDate].includes(
                element.inputType,
              ) &&
              // ...And it must not already be embedded in the PDF (because then we'd render it twice if we included it here)
              !this.getIsElementAlreadyEmbeddedInPdf(element) &&
              // ...And the value must be a valid date (this filters out things like Package IDs that are inputType === text)
              moment(element.value).isValid() &&
              // ...And it must have been submitted by the recipient
              element.recipient?.submitted === true &&
              (this.documentIdForElements === null ||
                this.documentIdForElements === document.id),
          )
          .forEach((element) => {
            visibleDates.push([
              element,
              {
                separatePageNumber: page.pageNumber,
                combinedPageNumber,
              },
            ]);
          });
        combinedPageNumber += 1;
      });
    });

    return visibleDates;
  };

  /**
   * Maps a date element from a signature package to
   * a date object that Hancock can use
   */
  private mapDateElementToHancockDate = (
    element: SignaturePackage.Element,
    pageNumber: number,
  ): Date => {
    return {
      x: this.shouldDoMagicAdjustment
        ? calculateMagicOffset(element.left, DEFAULT_X)
        : element.left,
      y: this.shouldDoMagicAdjustment
        ? calculateMagicOffset(element.top, DEFAULT_Y)
        : element.top,
      width: element.width,
      height: element.height,
      pageNumber,
      date: element.value!,
    };
  };

  /**
   * Maps a signature element from a signature package to
   * a signature object that Hancock can use
   */
  private mapSignatureElementToHancockSignature = (
    element: SignaturePackage.Element,
    pageNumber: number,
  ): Signature => {
    assert(!!element.recipient, 'No recipient found in signature element!');
    if (element.recipient.xfdfSignature) {
      return {
        x: this.shouldDoMagicAdjustment
          ? calculateMagicOffset(element.left, DEFAULT_X)
          : element.left,
        y: this.shouldDoMagicAdjustment
          ? calculateMagicOffset(element.top, DEFAULT_Y)
          : element.top,
        pageNumber,
        width: isNum(element.imageWidth)
          ? Number(element.imageWidth)
          : element.width,
        height: isNum(element.imageHeight)
          ? Number(element.imageHeight)
          : element.height,
        xfdf: element.recipient.xfdfSignature!,
      };
    }
    assert(
      !!element.recipient.image,
      'No image or XFDF found in signature element!',
    );
    return {
      x: this.shouldDoMagicAdjustment
        ? calculateMagicOffset(element.left, DEFAULT_X)
        : element.left,
      y: this.shouldDoMagicAdjustment
        ? calculateMagicOffset(element.top, DEFAULT_Y)
        : element.top,
      pageNumber,
      width: isNum(element.imageWidth)
        ? Number(element.imageWidth)
        : element.width,
      height: isNum(element.imageHeight)
        ? Number(element.imageHeight)
        : element.height,
      image: element.recipient.image!,
    };
  };

  /**
   * Some elements in a signature package may already be embedded into the
   * flattened PDF. For these elements, we do not want to pass them to
   * Hancock or else we'll experience double-rendering.
   */
  private getIsElementAlreadyEmbeddedInPdf = (
    element: SignaturePackage.Element,
  ) => {
    /**
     * If this element came from the Pro X experience, we may hackily
     * use this `font` field with a value of `prox_esign` to indicate
     * that the element is already embedded.
     */
    if (element.recipient?.font === 'prox_esign') return true;
    /**
     * If the attorney is not a signer, this element cannot be embedded
     * because only attorney elements are embedded in the PDF
     */

    if (this.signaturePackage.whoSigns === WhoSigns.Others) return false;

    /**
     * As of the version of e-sign released in Summer 2022, we no
     * longer embed signature/date elements in the PDF, so if this
     * package was created after that point, this element cannot
     * be embedded
     */
    if (this.signaturePackage.isUnifiedEsign) return false;

    /**
     * Since the attorney is a signer in this package, and attorneys
     * who "sign now" always have a signOrder of 0, if we find a
     * signOrder of 0, then we know that this element is embedded.
     * It's either a package created before the 2022 re-release of
     * e-sign, or it's a basic platform package
     */
    return element.recipient?.signOrder === 0;
  };
}

/**
 * Makes the document package that Hancock can render in a
 * read-only way for the details view.
 */
export const makeReadOnlyDocumentsForHancock = (
  signaturePackage: SignaturePackage.SignaturePackage,
): ReadDocuments => new HancockReadOnlyDocumentMaker(signaturePackage).make();

/**
 * Gets the package ID that we want Hancock to render
 * and the position on every page to render it at
 */
export const getPackageIdAndPosition = (
  signaturePackage: SignaturePackage.SignaturePackage,
) => {
  assert(signaturePackage.lawyawDocuments.length, 'No documents found!');
  assert(
    !!signaturePackage.lawyawDocuments[0]!.pages.length,
    'No pages found in first document!',
  );
  assert(
    !!signaturePackage.lawyawDocuments[0]!.pages[0]!.elements.length,
    'No elements found in first page of first document!',
  );
  const packageId =
    signaturePackage.lawyawDocuments[0]!.pages[0]!.elements.find(
      (e) => e.value && e.value.includes('Package ID'),
    );
  if (packageId === undefined) {
    throw new Error('No package ID found in signature package!');
  }
  return {
    position: {
      x: packageId.left,
      y: packageId.top,
    },
    id: packageId.value!,
  };
};
