import * as React from 'react';
import { UIFlags, FlagSetter, UIFlagsContext } from './uiFlags.context';
import { useCurrentOrg } from '../user';
import { getDefaultFlagsForMembership, setFlagsForMembership } from './utils';

export const UIFlagsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { org } = useCurrentOrg();
  const membershipId = org?.id;
  const isInternalOrg = !!org?.enable_dev_tools;
  const shouldPersistToLocalStorage = isInternalOrg;
  const [enabled, setEnabled] = React.useState(isInternalOrg);

  const [flags, setFlags] = React.useState(() => {
    if (!enabled) return UIFlags;
    return getDefaultFlagsForMembership(membershipId);
  });

  (window as any)['flags'] = () => setEnabled(true);

  React.useEffect(() => {
    if (!shouldPersistToLocalStorage) return;
    setFlagsForMembership(membershipId, flags);
  }, [flags, membershipId, shouldPersistToLocalStorage]);

  const setFlag: FlagSetter = (flag, enabled) =>
    setFlags((existing) => ({ ...existing, [flag]: enabled }));

  return (
    <UIFlagsContext.Provider value={{ enabled, flags, setFlag }}>
      {children}
    </UIFlagsContext.Provider>
  );
};
