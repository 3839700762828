import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Spinner from '~/src/components/Spinner';
import { SubmissionRow, SubmissionWrapper } from '../SubmissionWrapper';
import { useClioQuestionnaireSubmissions } from '../../../hooks';
import { useCurrentOrgFprint } from '~/src/entities/user';
import moment from 'moment';
import { IsDirtyMessage } from './IsDirtyMessage';
import { ExistingSubmissionMessage } from './ExistingSubmissionMessage';
import { tryConvertToNumber } from '../../../utils';
import analyticsService from '~/src/services/analytics';
import env from '~/src/utils/env';
import { DeleteSubmissionDialogModal } from '../../DeleteSubmissionDialog';

const DATE_FORMAT = 'M/D/YYYY';

export const SharedSubmission: React.FC<{
  questionnaireId: string;
  matterId: string;
  isDirty: boolean;
}> = ({ questionnaireId, matterId, isDirty }) => {
  const orgFprint = useCurrentOrgFprint();
  const {
    data: submissionData,
    isError: isQsError,
    isLoading: isQsLoading,
  } = useClioQuestionnaireSubmissions(orgFprint, {
    questionnaire_id: questionnaireId,
    target_identifier: matterId,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const submission = submissionData?.pages[0]?.submissions[0] || null;

  if (isQsLoading) {
    return (
      <Box
        sx={{
          paddingTop: '8px',
          width: '100%',
        }}
      >
        <Spinner />
      </Box>
    );
  }

  if (isQsError) {
    return (
      <Typography variant="h3">
        An error occured, please try again later...
      </Typography>
    );
  }

  const formattedDate = moment(submission?.created_at).format(DATE_FORMAT);

  if (!submission) {
    return <></>;
  }

  const handleSubmissionView = () => {
    analyticsService.track('Clicked on Live Interview', {
      questionnaire_id: submission.questionnaire_id,
      submission_id: submission.id,
      matter_id: tryConvertToNumber(submission.target_identifier),
    });
    window.open(
      env.basename +
        `/questionnaires/${submission.questionnaire_id}/responses/${submission.id}`,
      '_blank',
    );
  };

  return (
    <>
      {submission && (
        <DeleteSubmissionDialogModal
          open={showDeleteModal}
          submission={submission}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          width: '100%',
        }}
      >
        <Box paddingTop={'8px'}>
          {isDirty ? <IsDirtyMessage /> : <ExistingSubmissionMessage />}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '100%',
          }}
        >
          <Typography variant="h1">Shared on {formattedDate}</Typography>
          <SubmissionWrapper>
            <SubmissionRow
              matterTitle={submission?.questionnaire_version?.title}
              onDelete={() => setShowDeleteModal(true)}
              onView={handleSubmissionView}
            />
          </SubmissionWrapper>
        </Box>
      </Box>
    </>
  );
};
