import qs from 'qs';
import { QueryParams } from './types';
import env from '~/src/utils/env';

export const getQuestionnaireBaseUrl = () =>
  `${env.clioQuestionnaireBaseUrl}/api/v1/questionnaire/`;

export const getQuestionnaireSubmissionBaseUrl = () =>
  `${env.clioQuestionnaireBaseUrl}/api/v1/questionnaire_submission/`;

export const getAuthTokenUrl = (orgFprint: string) =>
  `${env.apiUrl}/questionnaires/org/${orgFprint}/token/`;

export const getQuestionnaireListUrl = (params?: Partial<QueryParams>) => {
  const stringified = params ? `?${qs.stringify(params)}` : '';

  return `${getQuestionnaireBaseUrl()}${stringified}`;
};

export const getQuestionnaireSubmissionListUrl = (
  params?: Partial<QueryParams>,
) => {
  const stringified = params
    ? `?${qs.stringify(params, { arrayFormat: 'brackets' })}`
    : '';

  return `${getQuestionnaireSubmissionBaseUrl()}${stringified}`;
};

export const getQuestionnaireSubmissionApprovalByIdUrl = (
  submissionId: string,
) => `${getQuestionnaireSubmissionBaseUrl()}${submissionId}/approve`;

export const getQuestionnaireSubmissionById = (submissionId: string) =>
  `${getQuestionnaireSubmissionBaseUrl()}${submissionId}`;

export const getTagMapsUrl = (orgFprint: string) =>
  `${env.apiUrl}/questionnaires/org/${orgFprint}/tags/`;

export const getQuestionnaireById = (questionnaireId: string) =>
  `${getQuestionnaireBaseUrl()}${questionnaireId}`;

export const getLogoAttachUrl = (questionnaireId: string) =>
  `${getQuestionnaireBaseUrl()}${questionnaireId}/attach_logo`;

export const getLogoRemoveUrl = (questionnaireId: string) =>
  `${getQuestionnaireBaseUrl()}${questionnaireId}/remove_logo`;

export const getQuestionnaireGenerateLink = (questionnaireId: string) =>
  `${getQuestionnaireBaseUrl()}${questionnaireId}/generate_link`;

export const getDuplicateQuestionnaireUrl = (questionnaireId: string) =>
  `${getQuestionnaireBaseUrl()}${questionnaireId}/duplicate`;

export const getQuestionnaireTemplateUrlById = (
  orgFprint: string,
  questionnaireId: string,
) =>
  `${getQuestionnaireTemplateListUrl(
    orgFprint,
  )}?questionnaire_id=${questionnaireId}`;

export const getQuestionnaireTemplateListUrl = (orgFprint: string) =>
  `${env.apiUrl}/questionnaires/api/v1/org/${orgFprint}/questionnaire-template/`;

export const getRelatedQuestionnaireSubmissionUrl = (orgFprint: string) =>
  `${env.apiUrl}/clients/api/v2/org/${orgFprint}/related-questionnaire-submission/`;

export const getRelatedQuestionnaireSubmissionIdUrl = (
  orgFprint: string,
  id: number,
) =>
  `${env.apiUrl}/clients/api/v2/org/${orgFprint}/related-questionnaire-submission/${id}/`;
