import React from 'react';
import { Prepare } from '@mystacksco/hancock';
import { AssignedAnnotationType } from '@mystacksco/hancock/dist/prepare/constants';
import {
  AssignedEvent,
  DeletedEvent,
  ModifiedAnnotationEvent,
  SignedEvent,
} from '@mystacksco/hancock/dist/prepare/events';
import assert from 'assert';
import ChooseDaterMenu from '~/src/components/SignerSelection/ChooseDaterMenu';
import {
  convertSignaturePackageToPrepareDocument,
  makeAssignmentElementPayload,
  makeDateNowElement,
  makeSignatureUpdatedElement,
  makeSignNowElement,
} from '~/src/components/SignerSelection/hancock';
import {
  OnAssignFields,
  OnDateNow,
  OnDeleteAssignedField,
  OnSignNow,
} from '~/src/components/SignerSelection/SignerSelectionCallbacks';

import {
  getAllSigners,
  hexToRgb,
} from '~/src/components/SignerSelection/utils';
import ChooseSignerMenu from '~/src/components/SignerSelection/ChooseSignerMenu';
import ChangeDaterMenu from '~/src/components/SignerSelection/ChangeDaterMenu';
import ChangeSignatureMenu from '~/src/components/SignerSelection/ChangeSignatureMenu';
import { RgbOrRgba } from '@mystacksco/hancock/dist/types';
import { SIGNER_INDICATOR_COLORS } from '~/src/components/SignerSelection/constants';
import { AnnotationMetadata } from '@mystacksco/hancock/dist/prepare/schema';
import {
  Signers,
  Pdf,
  Coordinates,
  SignedWithImage,
  Signer,
} from '~/src/models';

const UNASSIGNED_ANNOTATION_COLOR = '#ADD8E6';
const ME_COLOR = '#000000';
const ANNOTATION_OPACITY = 0.25;

/**
 * This is a wrapper around the hancock library that
 * is mainly used to manage event listeners. For example,
 * when Hancock tells us that the attorney has signed,
 * this wrapper handles the callbacks for that event.
 *
 * This wrapper also handles other functionality, like
 * switching between documents.
 */
class HancockWrapper {
  // The email of the last person to which an element was assigned during this session
  lastAssignedToEmail: string | null = null;

  // The ID of the current PDF being viewed
  private currentPdfId: string | null = null;

  /**
   * gets the current package signers
   */
  get signers() {
    return this._signers;
  }

  /**
   * Sets the current package signers and also
   * updates the colors of the annotations for
   * Hancock to use when rendering
   */
  set signers(newSigners: Signers) {
    this._signers = newSigners;
    this.updateSignerColors();
  }

  /**
   * Updates the array of PDFs in the signature package
   */
  set pdfs(newPdfs: Pdf[]) {
    this._pdfs = newPdfs;
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    // TODO: Should this one be public?
    private hancock: Prepare,
    private _signers: Signers,
    private _pdfs: Pdf[],
    public onSignNow: OnSignNow,
    public onAssignFields: OnAssignFields,
    public onDeleteAssignedField: OnDeleteAssignedField,
    public onDateNow: OnDateNow, // eslint-disable-next-line no-empty-function
  ) {}

  /**
   * This is triggered when the user clicks to drop
   * and assignment on the document
   *
   * @param type
   * @param coordinates
   * @returns {void}
   */
  handleGhostDrop = async (
    type: 'signature' | 'date',
    { x, y }: Coordinates,
  ) => {
    let id: string | null = null;
    if (type === 'signature') {
      id = this.hancock.addUnassignedSignature(x, y);
    } else if (type === 'date') {
      id = this.hancock.addUnassignedDate(x, y);
    }
    assert(!!id);
    // Assign this annotation to whoever we assigned one to most recently, if any
    if (!this.lastAssignedToEmail) return;
    this.hancock.assign(id, this.lastAssignedToEmail);
  };

  /**
   * When the user signs a field, this callback bubbles that event
   * up in the "element" format used by our API.
   *
   * NOTE: Because this is an event listener callback, it relies
   *       on refs to read data from any props that can change.
   *
   * @param annotationId - the ID of the signature annotation
   * @param metadata - The metadata (e.g. x/y/width/height) of the annotation
   * @param xfdf - The serialized annotation in XML/XFDF format
   */
  handleSigned: SignedEvent = async (annotationId, metadata, xfdf) => {
    assert('me' in this.signers);
    this.lastAssignedToEmail = this.signers.me.email;
    const docId = this.hancock.currentDocumentId!;
    const pdf = this._pdfs.find((e) => e.id === docId)!;

    const assignee = makeSignNowElement(
      this.signers.me,
      metadata,
      pdf,
      Number(metadata.id) || undefined,
    );
    const { width, height } = metadata;
    const elementId = await this.onSignNow(docId, assignee, {
      xfdfSignature: xfdf,
      width,
      height,
    });
    this.hancock.addMetadata(annotationId, 'id', elementId.toString());
    // TODO: Update all signature elements *not* in the current document
    assert(!!this.currentPdfId);
    /**
     * These promises update the image height/width of all signatures in
     * the signature package (besides those on the curren document, which
     * are handled by event listeners established in this class).
     */
    const promises = this._pdfs
      .filter((pdf) => pdf.id !== this.currentPdfId)
      .map((each) => {
        const mySignatureElements = each.signatures
          .filter((e): e is SignedWithImage => e.type === 'signed-with-image')
          .map(
            (e): SignedWithImage => ({
              ...e,
              imageWidth: width,
              imageHeight: height,
            }),
          );
        return this.onAssignFields(each.id, mySignatureElements);
      })
      .flat();
    await Promise.all(promises);
  };

  /**
   * When an assigned field moved on the page, this callback
   * bubble that event up in the "element" format used by our API.
   *
   * NOTE: Because this is an event listener callback, it relies
   *       on refs to read data from any props that can change.
   *
   * @param annotationId - the ID of the signature annotation
   * @param assigneeEmail - The email of the person to whom this annotation is assignment
   * @param type - The type of element/annotation (signature, signature assignment, date, date assignment)
   * @param metadata - The metadata (e.g. x/y/width/height) of the annotation
   * @param xfdf - The serialized annotation in XML/XFDF format
   */
  handleModified: ModifiedAnnotationEvent = async (
    annotationId,
    assigneeEmail,
    type,
    metadata,
    xfdf,
  ) => {
    const docId = this.hancock.currentDocumentId!;
    const pdf = this._pdfs.find((e) => e.id === docId)!;
    const allSigners = getAllSigners(this.signers);
    assert(!!metadata.id);
    if (type === AssignedAnnotationType.MyDate) {
      const element = makeDateNowElement(
        allSigners.find((e) => e.email === assigneeEmail)!,
        metadata,
        pdf,
        Number(metadata.id),
      );
      this.onDateNow(docId, element);
    } else if (type === AssignedAnnotationType.MySignature) {
      const element = makeSignatureUpdatedElement(
        allSigners.find((e) => e.email === assigneeEmail)!,
        metadata,
        pdf,
        xfdf,
        Number(metadata.id),
      );
      this.onAssignFields(docId, [element]);
    } else {
      const element = makeAssignmentElementPayload(
        allSigners.find((e) => e.email === assigneeEmail)!,
        type,
        metadata,
        pdf,
        Number(metadata.id),
      );
      this.onAssignFields(docId, [element]);
    }
  };

  /**
   * When the attorney deletes an annotation, bubble that
   * event up.
   *
   * @param annotationId - the ID of the signature annotation
   * @param assigneeEmail - The email of the person to whom this annotation is assignment
   * @param type - The type of element/annotation (signature, signature assignment, date, date assignment)
   * @param metadata - The metadata (e.g. x/y/width/height) of the annotation
   */
  handleDeletion: DeletedEvent = (
    annotationId,
    assigneeEmail,
    type,
    metadata,
  ) => {
    assert(!!metadata.id);
    this.onDeleteAssignedField(Number(metadata.id));
  };

  /**
   * When a field is assigned to a signer, this callback
   * bubble that event up in the "element" format used by our API.
   *
   * NOTE: Because this is an event listener callback, it relies
   *       on refs to read data from any props that can change.
   *
   * @param annotationId - the ID of the signature annotation
   * @param assigneeEmail - The email of the person to whom this annotation is assignment
   * @param type - The type of element/annotation (signature, signature assignment, date, date assignment)
   * @param metadata - The metadata (e.g. x/y/width/height) of the annotation
   */
  handleAssigned: AssignedEvent = async (
    annotationId,
    assigneeEmail,
    type,
    metadata,
  ) => {
    const docId = this.hancock.currentDocumentId!;
    const pdf = this._pdfs.find((e) => e.id === docId)!;

    this.lastAssignedToEmail = assigneeEmail;

    const allSigners = getAllSigners(this.signers);

    if (type === AssignedAnnotationType.MyDate) {
      const assignee = makeDateNowElement(
        allSigners.find((e) => e.email === assigneeEmail)!,
        metadata,
        pdf,
        Number(metadata.id) || undefined,
      );
      const elementId = await this.onDateNow(docId, assignee);
      this.hancock.addMetadata(annotationId, 'id', elementId.toString());
    } else {
      const assignee = makeAssignmentElementPayload(
        allSigners.find((e) => e.email === assigneeEmail)!,
        type,
        metadata,
        pdf,
        Number(metadata.id) || undefined,
      );
      const [elementId] = await this.onAssignFields(docId, [assignee]);
      this.hancock.addMetadata(annotationId, 'id', elementId!.toString());
    }
  };

  /**
   * This popover is rendered when the user clicks on an annotation. Depending
   * on the type of annotation in question, a menu will be rendered that allows
   * for different types of actions. For example, if the annotation is a signature,
   * the menu will allow the user to change the signature. If the annotation is an
   * assignment, the menu will allow the user to change the assignee.
   *
   * @param annotationId - the ID of the signature annotation
   * @param annotationMetadata - Info about what type of annotation this is and to whom it's assigned
   * @returns
   */
  renderPopoverMenu = (
    annotationId: string,
    annotationMetadata: AnnotationMetadata,
  ) => {
    const assignToMe = () => {
      assert('me' in this.signers);
      this.hancock.assign(annotationId, this.signers!.me.email);
    };
    const deleteAssignment = () => {
      this.hancock.delete(annotationId);
    };
    const changeSignature = () => {
      this.hancock.changeSignature(annotationId);
    };
    if (annotationMetadata.type === AssignedAnnotationType.DateAssignment) {
      return (
        <ChooseDaterMenu
          signers={this.signers!}
          daterEmail={
            annotationMetadata.assigned
              ? annotationMetadata.assigneeEmail
              : null
          }
          onDateNow={assignToMe}
          onDelete={deleteAssignment}
          onDaterSelected={(email: string) => {
            this.hancock.assign(annotationId, email);
          }}
        />
      );
    }
    if (
      annotationMetadata.type === AssignedAnnotationType.SignatureAssignment
    ) {
      return (
        <ChooseSignerMenu
          signers={this.signers!}
          signerEmail={
            annotationMetadata.assigned
              ? annotationMetadata.assigneeEmail
              : null
          }
          onSignNow={assignToMe}
          onDelete={deleteAssignment}
          onSignerSelected={(email: string) => {
            this.hancock.assign(annotationId, email);
          }}
        />
      );
    }
    if (annotationMetadata.type === AssignedAnnotationType.MySignature) {
      return (
        <ChangeSignatureMenu
          signers={this.signers!}
          onChangeSignature={changeSignature}
          onDelete={deleteAssignment}
          onSignerSelected={(signer: Signer) => {
            this.hancock.assign(annotationId, signer.email);
          }}
        />
      );
    }
    if (annotationMetadata.type === AssignedAnnotationType.MyDate) {
      return (
        <ChangeDaterMenu
          signers={this.signers!}
          onDelete={deleteAssignment}
          onDaterSelected={(signer: Signer) => {
            this.hancock.assign(annotationId, signer.email);
          }}
        />
      );
    }
  };

  /**
   * This callback is used by the user to switch between documents in the view
   *
   * @param pdf - The PDF which we want to be viewing
   * @param maybeXfdfSignature - The attorney's current signature, if any
   */
  async switchDocument(pdf: Pdf, maybeXfdfSignature?: string) {
    const document = convertSignaturePackageToPrepareDocument(
      pdf,
      this.signers,
      maybeXfdfSignature,
    );
    this.currentPdfId = pdf.id;
    await this.hancock.loadDocument(document);
    this.updateSignerColors();
    this.movePackageIdsToBottom(pdf);
  }

  /**
   * Based on the current signers in the package, update
   * Hancock to use specific colors when rendering the
   * annotations on the document.
   */
  private updateSignerColors() {
    const { r, g, b } = hexToRgb(UNASSIGNED_ANNOTATION_COLOR)!;
    this.hancock.setUnassignedColor([r, g, b, 1]);
    if ('me' in this.signers) {
      const { r, g, b } = hexToRgb(ME_COLOR)!;
      const color: RgbOrRgba = [r, g, b, ANNOTATION_OPACITY];
      this.hancock.setSignerColor(this.signers.me.email, color);
    }
    if ('others' in this.signers) {
      this.signers.others.forEach(({ email }, i) => {
        const { r, g, b } = hexToRgb(SIGNER_INDICATOR_COLORS[i]!)!;
        const color: RgbOrRgba = [r, g, b, ANNOTATION_OPACITY];
        this.hancock.setSignerColor(email, color);
      });
    }
  }

  /**
   * When the document is loaded, this function makes an API request to move the
   * package IDs to the bottom of each page for the document. Why do this here
   * instead of simply having the package ID for each page already at the bottom?
   * Good question! The reason is that as of now (May 2022) we have no way on the
   * backend to tell what size each page is. We only have that ability on the frontend.
   * And while most documents are the same size, some will not be and we don't want
   * to deal with weird missing package IDs in the future.
   *
   * FIXME: We should really add PDFTron to the backend API so that we can remove
   * this logic and simply default package IDs to the bottom of each page.
   *
   * @param pdf - The PDF on which we are moving package IDs
   */
  movePackageIdsToBottom = (pdf: Pdf) => {
    let width = 0;
    let height = 0;
    // If the document is not uniform in dimensions, throw an error and exit
    try {
      const { width: w, height: h } = this.hancock.documentDimensions;
      width = w;
      height = h;
    } catch (err) {
      console.error('Could not move package IDs to bottom of page! ', err);
      return;
    }

    const packageIds = pdf.packageIds.map((e) => {
      const relocatedPackageId = { ...e };
      const TOP_MARGIN = 15;
      // If the document size is not uniform for some reason, gracefully fail
      // Move to the bottom of the page mins a slight margin
      const newTop = height - TOP_MARGIN;
      const LEFT_MARGIN = 180;
      // Move to the middle of the page mins a slight margin (which basically keeps it in the middle)
      const newLeft = width / 2 - LEFT_MARGIN;
      relocatedPackageId.top = newTop;
      relocatedPackageId.left = newLeft;
      return relocatedPackageId;
    });
    this.onAssignFields(pdf.id, packageIds);
  };

  // Destroy any event listeners
  destroy = () => {
    this.hancock.destroy();
  };
}

export default HancockWrapper;
