/* Libraries */
import React from 'react';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import { SettingsSubscription } from './SettingsSubscriptionV1';
import { SettingsSubscriptionV2 } from './SettingsSubscriptionV2';
import SettingsSubscriptionV2FreeTrial from './SettingsSubscriptionV2freeTrial';

/* Configurations */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { canOrgUseFeatureFlag, getOrg } from '~/src/entities/user';

class SettingsSubscriptionContainer extends React.Component {
  componentDidMount() {
    this.props.store.user.currentOrganization.fetchSubscription();
  }

  handleCreateSubscription = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.createSubscription);
  };

  render() {
    const org = getOrg();
    const isClioFree = canOrgUseFeatureFlag('CLIO_FREE', org);
    const { subscription } = this.props.store.user.currentOrganization;
    const loading = this.props.store.user.currentOrganization.isLoading();

    const isV2Subscriptions = canOrgUseFeatureFlag('SUBSCRIPTIONS_V2', org);

    if (isV2Subscriptions) {
      const { subscription } = this.props.store.user.currentOrganization;

      if (subscription && subscription.freeTrialIsActive()) {
        return <SettingsSubscriptionV2FreeTrial subscription={subscription} />;
      }

      return (
        <SettingsSubscriptionV2
          loading={loading}
          subscription={subscription}
          isClioFree={isClioFree}
          onCreateNewSubscription={this.handleCreateSubscription}
        />
      );
    }
    return (
      <SettingsSubscription
        loading={loading}
        subscription={subscription}
        isClioFree={isClioFree}
        onCreateNewSubscription={this.handleCreateSubscription}
      />
    );
  }
}

export const SettingsSubscriptionPage = inject((store) => store)(
  WithLayoutProps(observer(SettingsSubscriptionContainer)),
);
