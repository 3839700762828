import React from 'react';
import { Button, DialogTitle } from '@mui/material';
import { ListViewContainer } from '../shared/ListView';
import { useClioQuestionnaireSubmissions } from '../../hooks/useClioQuestionnaireSubmissions';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { QuestionnaireSubmission } from '@clio/questionnaire-builder';
import { RelatedMatterRow } from './RelatedMatterRow';
import { MessageDialog } from '~/src/components/Modal/mui';
import { MessageDialogError, MessageDialogLoading } from '../shared';

interface Props {
  questionnaireId: string;
  onClose: () => void;
  onConfirmDelete: () => void;
}

export const DeleteQuestionnaireDialogContents = ({
  questionnaireId,
  onClose,
  onConfirmDelete,
}: Props) => {
  const orgFprint = useCurrentOrgFprint();

  const { data, isError, isLoading } = useClioQuestionnaireSubmissions(
    orgFprint,
    {
      questionnaire_id: questionnaireId,
    },
  );

  if (isError) {
    return <MessageDialogError onClose={onClose} />;
  }

  if (isLoading) {
    return <MessageDialogLoading onClose={onClose} />;
  }

  const submissions = data?.pages[0]?.submissions || [];

  if (submissions.length === 0) {
    return (
      <MessageDialog
        title={'Delete Interview Template'}
        onClose={onClose}
        dialogActions={
          <>
            <Button onClick={onClose}>Close</Button>
            <Button onClick={onConfirmDelete} variant="red">
              Delete
            </Button>
          </>
        }
      >
        <strong>Warning: this action is permanent.</strong>
        <br />
        <br />
        Document sets in this template and Live Interviews will not be affected.
      </MessageDialog>
    );
  }

  return (
    <MessageDialog
      title={'Delete the interview template with active recipients?'}
      onClose={onClose}
      dialogActions={
        <>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={onConfirmDelete} variant="red">
            Delete
          </Button>
        </>
      }
    >
      This interview template has active recipients answering questions. If you
      delete it, you will no longer have access to the questions and logics in
      the builder view. <br />
      <br />
      Are you sure you want to{' '}
      <span style={{ color: '#DC2626' }}>permanently delete</span> this
      interview template?
      <br />
      <br />
      <DialogTitle className="modalHeading modalSubHeading">
        Matters using this interview template
      </DialogTitle>
      <ListViewContainer>
        {submissions.map((submission: QuestionnaireSubmission) => (
          <RelatedMatterRow key={submission.id} submission={submission} />
        ))}
      </ListViewContainer>
    </MessageDialog>
  );
};
