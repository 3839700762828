import {
  useCheckStateFormsEnabled,
  useClioQuestionnairesEnabled,
  useCourtFormsEnabled,
  useFeatureFlag,
  useFederalCourtFormsEnabled,
  useIsCanary,
  useIsClioFree,
  useIsUnifiedPlatform,
  useQuestionnairesEnabled,
  useWordDocumentAutomationEnabled,
} from '../entities/user';

const useFeatureFlags = () => {
  const isClioFreePlan = useIsClioFree();
  const isUnifiedPlatform = useIsUnifiedPlatform();
  const isCourtFormsEnabled = useCourtFormsEnabled();
  const doesFeatureFlagForThisStateCodeExist = useCheckStateFormsEnabled();
  const isQuestionnairesEnabled = useQuestionnairesEnabled();
  const isClioQuestionnaireEnabled = useClioQuestionnairesEnabled();
  const isWordDocumentAutomationEnabled = useWordDocumentAutomationEnabled();
  const isFederalCourtFormsEnabled = useFederalCourtFormsEnabled();
  const isAddinLongDocumentsEnabled = useFeatureFlag('ADDIN_LONG_DOCUMENTS');
  const isCanary = useIsCanary();

  return {
    isClioFreePlan,
    doesFeatureFlagForThisStateCodeExist,
    isQuestionnairesEnabled,
    isClioQuestionnaireEnabled,
    isWordDocumentAutomationEnabled,
    isFederalCourtFormsEnabled,
    isCourtFormsEnabled,
    isUnifiedPlatform,
    isAddinLongDocumentsEnabled,
    isCanary,
  };
};

export default useFeatureFlags;
