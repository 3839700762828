/* Libs */
import { types, flow, getParent, destroy, getRoot } from 'mobx-state-tree';

/* Services */
import projectsService from '~/src/services/projects';

/* Stores */

import { getOrgFprintFromStoreNode } from '../utils';
import { Project } from './projectStore';

const ProjectsStore = types
  .model('ProjectsStore', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    dictionary: types.optional(types.map(Project), {}),
    list: types.optional(types.array(types.reference(Project)), []),
    curProjectId: types.maybe(types.number),
  })
  .actions((self) => {
    const addProject = (draft) => {
      if (self.list.indexOf(draft.identifier) === -1) {
        self.list.push(draft.identifier);
      }
      // Update the existing project if it exists or start with an empty object
      const newProject = self.dictionary.get(draft.identifier) || {};
      Object.keys(draft).forEach((key) => {
        newProject[key] = draft[key];
      });

      self.dictionary.set(draft.identifier, newProject);
      return self.dictionary.get(draft.identifier);
    };

    const addAllProjects = (projects) => {
      projects.forEach((project) => self.addProject(project));
    };

    const createDocSet = flow(function* createDocSet(
      templateIds,
      name,
      matterId,
    ) {
      try {
        self.error = '';
        self.isLoading = true;

        // case 1, create project from side bar
        const sideBarItemsHaveDefaultValues =
          getRoot(self).sidebarItems.hasTemplateDefaultValues();
        // case 2, create project from template sets list page dropdown buttons
        const templateSetHasDefaultValues = templateIds.some(
          (id) => id.split(' ').length == 2,
        );
        const response = yield projectsService.createDocSet(
          getOrgFprintFromStoreNode(self),
          templateIds,
          name,
          matterId,
          sideBarItemsHaveDefaultValues || templateSetHasDefaultValues ? 2 : 1,
        );
        self.isLoading = false;
        if (response.task_id) {
          return response;
        } else {
          const nextProject = self.addProject(response);
          return nextProject;
        }
      } catch (error) {
        console.error('Failed to create document set draft: ', error);
        self.error = typeof error === 'string' ? error : 'Something went wrong';
        self.isLoading = false;
        throw error;
      }
    });

    const fetchQuestionnaireFormProjectData = flow(
      function* fetchQuestionnaireFormProjectData(projectFprint) {
        const project = yield projectsService.getQuestionnaireFormProjectData(
          projectFprint,
        );
        const nextProject = self.addProject(project);
        return nextProject;
      },
    );

    /* TODO: deprecate */
    const fetchProjectData = flow(function* fetchProjectData(projectId, name) {
      try {
        self.isLoading = true;

        const project = yield projectsService.fetchProject(
          getOrgFprintFromStoreNode(self),
          projectId,
          name,
        );
        const nextProject = self.addProject(project);

        self.isLoading = false;

        return nextProject;
      } catch (error) {
        self.isLoading = false;
        console.error('Failed to fetch project data', error);
      }
    });

    const deleteProject = flow(function* (projectId) {
      const project = self.getProject(projectId);

      if (project) {
        try {
          const rootStore = getParent(self);

          if (
            rootStore &&
            rootStore.matter &&
            rootStore.matter.list &&
            rootStore.matter.list.length > 0
          ) {
            rootStore.matter.list.flat().forEach((matter) => {
              if (project.documents.length > 1) {
                matter.removeDocumentSet(projectId);
              } else {
                matter.removeDocument(projectId);
              }
            });
          }

          yield project.deleteSelf();
          self.list = self.list.filter((curr) => curr.id !== project.id);
          self.dictionary.delete(project.id);
          destroy(project);

          return true;
        } catch (error) {
          console.error('Failed to delete doc set', error);
        }
      }

      return false;
    });

    const updateProject = flow(function* updateProject(projectId, projectData) {
      const project = self.getProject(projectId);

      if (!project) {
        return false;
      }

      try {
        yield project.update(projectData);
        return true;
      } catch (error) {
        console.error('Failed to update project : ', error);
        return false;
      }
    });

    const fetchProject = flow(function* fetchProject(
      projectId,
      getPDFElements,
    ) {
      const project = yield projectsService.fetchProject(
        getOrgFprintFromStoreNode(self),
        projectId,
        getPDFElements,
      );
      return self.addProject(project);
    });

    const duplicateDocument = flow(function* duplicateDocument(
      projectID,
      documentID,
      numberOfDuplicates,
    ) {
      try {
        const project = yield projectsService.duplicateDocument(
          getOrgFprintFromStoreNode(self),
          projectID,
          documentID,
          numberOfDuplicates,
        );
        return self.addProject(project);
      } catch (error) {
        return false;
      }
    });

    const updateProjectDocuments = flow(function* updateProjectDocuments(
      projectID,
      documents,
    ) {
      try {
        const response = yield projectsService.updateProjectDocuments(
          getOrgFprintFromStoreNode(self),
          projectID,
          documents,
        );

        return response;
      } catch (error) {
        return false;
      }
    });

    const setProjectId = (value) => {
      self.curProjectId = value;
    };

    return {
      fetchProject,
      duplicateDocument,
      updateProjectDocuments,
      fetchProjectData,
      fetchQuestionnaireFormProjectData,
      addProject,
      createDocSet,
      updateProject,
      deleteProject,
      addAllProjects,
      setProjectId,
    };
  })
  .views((self) => {
    function getProject(projectId) {
      const id = parseInt(projectId);
      return self.dictionary.get(`document:${id}`);
    }

    // TODO: Remove this
    function documentSetTableList() {
      const documentSets = self.list.filter((project) =>
        project.isDocumentSet(),
      );

      return documentSets.map((documentSet) => ({
        id: documentSet.id,
        fprint: documentSet.fprint,
        title: documentSet.name,
        updatedAt: documentSet.updatedAt,
        documents: documentSet.documents,
        numDocs: documentSet.documents ? documentSet.documents.length : 0,
      }));
    }

    return {
      getProject,
      documentSetTableList,
    };
  });

export default ProjectsStore;
