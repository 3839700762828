import { APIErrorNotOk } from '~/src/utils/error';
import { getAuthTokenUrl } from './urls';
import {
  type QuestionnaireSubmission,
  type Document,
  type QuestionSubmissionType,
  getQuestionSubmissionResponse,
  getQuestionVersionFromQuestionSubmission,
  getQuestionSubmissionSelectedOptions,
  Field,
  FieldGroup,
  MultipleChoiceOption,
} from '@clio/questionnaire-builder';
import moment from 'moment';

const dateFormat = 'MM/DD/YYYY';

export const getClioQuestionnaireAuthToken = async (orgFprint: string) => {
  const response = await fetch(getAuthTokenUrl(orgFprint), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new APIErrorNotOk(response);
  }

  const json = await response.json();

  return json.questionnaire_auth_token;
};

export const questionnaireAuthorizationHeaders = (token: string) => ({
  questionnaire_auth_token: token,
});

// return dict of the form { Attribute.id => [ attribute options ] } for
// multiple choice and single select attributes
const getAttributeTagToOptionsMap = (documents: Document[]) => {
  const optionsMap: Record<string, MultipleChoiceOption[]> = {};

  documents.forEach((document: Document) => {
    Object.values(document.field_groups).forEach((fieldGroup: FieldGroup) => {
      Object.values(fieldGroup.fields).forEach((field: Field) => {
        if (
          field.type === 'QuestionMultipleChoiceSingle' ||
          field.type === 'QuestionMultipleChoiceMulti'
        ) {
          optionsMap[field.field_group_field_id] =
            field.multiple_choice_options;
        }
      });
    });
  });

  return optionsMap;
};

const getExternalFieldIdToNormalizedFieldDict = (
  documents: Document[],
): Record<string, string> => {
  const map: Record<string, string> = {};

  documents.forEach((document: Document) => {
    Object.values(document.field_groups).forEach((fieldGroup: FieldGroup) => {
      Object.values(fieldGroup.fields).forEach((field: Field) => {
        map[field.field_group_field_id] =
          fieldGroup.normalized_field_group + '.' + field.normalized_field;
      });
    });
  });

  return map;
};

export const getQuestionnaireQuestionArray = (
  questionnaireSubmission: QuestionnaireSubmission,
) => {
  const questionnaireQuestionArray: {
    [key: string]: any;
  }[] = [];

  const questionSubmissions =
    questionnaireSubmission.section_submissions_attributes.flatMap(
      (sectionSubmission) => sectionSubmission.question_submissions_attributes,
    );

  questionSubmissions.forEach((questionSubmission) => {
    const questionVersion = getQuestionVersionFromQuestionSubmission(
      questionnaireSubmission.questionnaire_version,
      questionSubmission,
    );

    const questionSubmissionType: QuestionSubmissionType =
      questionSubmission.question_submission_type;

    let responseLabel: string | null = '';
    if (questionVersion !== undefined) {
      switch (questionSubmissionType) {
        case 'QuestionSubmissionShort':
        case 'QuestionSubmissionLong': {
          responseLabel = questionSubmission.response_text;
          break;
        }
        case 'QuestionSubmissionDate': {
          responseLabel = questionSubmission.response_date;
          break;
        }
        case 'QuestionSubmissionMultipleChoiceSingle': {
          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );
          responseLabel = selectedOptions[0]!.label;
          break;
        }
        case 'QuestionSubmissionMultipleChoiceMulti': {
          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );
          const optionLabelArray = selectedOptions.map((o) => o.label);
          responseLabel = JSON.stringify(optionLabelArray);
          break;
        }
      }
    }

    questionnaireQuestionArray.push({
      question_version_id: questionVersion!.id,
      question_id: questionVersion!.question_id,
      question: questionVersion!.title,
      response: responseLabel,
      questionSubmissionType,
      mapping_fields: questionVersion!.question_mapping_versions,
    });
  });

  return questionnaireQuestionArray;
};

// derive a map of tag -> response from the questionnaire submission
export const getNormalizedField2ResponseMap = (
  questionnaireSubmission: QuestionnaireSubmission,
  documents: Document[],
) => {
  const responseDict: { [key: string]: string } = {};

  const questionSubmissions =
    questionnaireSubmission.section_submissions_attributes.flatMap(
      (sectionSubmission) => sectionSubmission.question_submissions_attributes,
    );

  const attributeTagToOptionsMap = getAttributeTagToOptionsMap(documents);

  const externalFieldIdToNormalizedFieldDict =
    getExternalFieldIdToNormalizedFieldDict(documents);

  questionSubmissions.forEach((questionSubmission) => {
    const questionVersion = getQuestionVersionFromQuestionSubmission(
      questionnaireSubmission.questionnaire_version,
      questionSubmission,
    );

    const questionSubmissionType: QuestionSubmissionType =
      questionSubmission.question_submission_type;

    if (questionVersion !== undefined) {
      switch (questionSubmissionType) {
        case 'QuestionSubmissionShort':
        case 'QuestionSubmissionLong':
        case 'QuestionSubmissionDate': {
          const response = getQuestionSubmissionResponse(questionSubmission);
          questionVersion.question_mapping_versions.forEach((mapping) => {
            const normalizedField =
              externalFieldIdToNormalizedFieldDict[
                mapping.external_field_identifier
              ];

            if (normalizedField) {
              if (questionSubmissionType == 'QuestionSubmissionDate') {
                responseDict[normalizedField] = response
                  ? moment.utc(response).format(dateFormat)
                  : '';
              } else {
                responseDict[normalizedField] = response ?? '';
              }
            }
          });
          break;
        }

        case 'QuestionSubmissionMultipleChoiceSingle': {
          const responseCollection: string[] = [];

          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );

          const questionMapping =
            questionVersion.question_mapping_versions.length > 0
              ? questionVersion.question_mapping_versions[0]
              : undefined;
          if (questionMapping === undefined) {
            break;
          }
          // tagStr is {normalized_entity}.{normalized_attribute}
          const tagStr = questionMapping.external_field_identifier;
          const lawyawOptions = attributeTagToOptionsMap[tagStr];

          if (lawyawOptions !== undefined && lawyawOptions.length > 0) {
            // a single-select question maps a single-select field
            lawyawOptions.forEach((lawyawOption: any) => {
              const selectedOption = selectedOptions.find((selectedOption) =>
                selectedOption.question_option_mapping_versions.some(
                  (optionMapping) =>
                    parseInt(optionMapping.external_field_identifier) ===
                    lawyawOption.id,
                ),
              );
              if (selectedOption !== undefined) {
                responseCollection.push(lawyawOption.value);
              }
            });
          } else {
            // a single-select question maps a text field
            if (selectedOptions && selectedOptions[0]) {
              responseCollection.push(selectedOptions[0].label);
            }
          }

          const normalizedField =
            externalFieldIdToNormalizedFieldDict[
              questionMapping.external_field_identifier
            ];

          if (normalizedField === undefined) {
            break;
          }

          responseDict[normalizedField] = responseCollection[0] ?? '';
          break;
        }

        case 'QuestionSubmissionMultipleChoiceMulti': {
          let responseCollection: string[] = [];

          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );

          const questionMapping =
            questionVersion.question_mapping_versions.length > 0
              ? questionVersion.question_mapping_versions[0]
              : undefined;
          if (questionMapping === undefined) {
            break;
          }
          // tagStr is {normalized_entity}.{normalized_attribute}
          const tagStr = questionMapping.external_field_identifier;
          const lawyawOptions = attributeTagToOptionsMap[tagStr];

          if (lawyawOptions !== undefined && lawyawOptions.length > 0) {
            // a multi-select question maps a multi-select field
            lawyawOptions.forEach((lawyawOption: any) => {
              const selectedOption = selectedOptions.find((selectedOption) =>
                selectedOption.question_option_mapping_versions.some(
                  (optionMapping) =>
                    parseInt(optionMapping.external_field_identifier) ===
                    lawyawOption.id,
                ),
              );
              if (selectedOption !== undefined) {
                responseCollection.push(lawyawOption.value);
              } else {
                // need to include empty strings for each option that was not selected specifically
                // for multiselect.  This is how Lawyaw's populate page works.
                responseCollection.push('');
              }
            });
          } else {
            // a multi-select question maps a text field
            responseCollection = selectedOptions.map(
              (selectedOptions) => selectedOptions.label,
            );
          }

          const normalizedField =
            externalFieldIdToNormalizedFieldDict[
              questionMapping.external_field_identifier
            ];

          if (normalizedField === undefined) {
            break;
          }

          responseDict[normalizedField] = responseCollection.join(', ');
          break;
        }
      }
    }
  });

  return responseDict;
};

export const getFieldId2ResponseMap = (
  questionnaireSubmission: QuestionnaireSubmission,
  documents: Document[],
) => {
  const responseDict: { [key: string]: string } = {};

  const questionSubmissions =
    questionnaireSubmission.section_submissions_attributes.flatMap(
      (sectionSubmission) => sectionSubmission.question_submissions_attributes,
    );

  const attributeTagToOptionsMap = getAttributeTagToOptionsMap(documents);

  questionSubmissions.forEach((questionSubmission) => {
    const questionVersion = getQuestionVersionFromQuestionSubmission(
      questionnaireSubmission.questionnaire_version,
      questionSubmission,
    );

    const questionSubmissionType: QuestionSubmissionType =
      questionSubmission.question_submission_type;

    if (questionVersion !== undefined) {
      switch (questionSubmissionType) {
        case 'QuestionSubmissionShort':
        case 'QuestionSubmissionLong':
        case 'QuestionSubmissionDate': {
          const response = getQuestionSubmissionResponse(questionSubmission);
          questionVersion.question_mapping_versions.forEach((mapping) => {
            if (questionSubmissionType == 'QuestionSubmissionDate') {
              responseDict[mapping.external_field_identifier] = response
                ? moment.utc(response).format(dateFormat)
                : '';
            } else {
              responseDict[mapping.external_field_identifier] = response ?? '';
            }
          });
          break;
        }

        case 'QuestionSubmissionMultipleChoiceSingle': {
          const responseCollection: string[] = [];

          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );

          const questionMapping =
            questionVersion.question_mapping_versions.length > 0
              ? questionVersion.question_mapping_versions[0]
              : undefined;
          if (questionMapping === undefined) {
            break;
          }
          // tagStr is {Entity.id}.{Attribute.id} or {normalized_entity}.{normalized_attribute}
          const tagStr = questionMapping.external_field_identifier;
          const lawyawOptions = attributeTagToOptionsMap[tagStr];

          if (lawyawOptions !== undefined && lawyawOptions.length > 0) {
            // a single-select question maps a single-select field
            lawyawOptions.forEach((lawyawOption: any) => {
              const selectedOption = selectedOptions.find((selectedOption) =>
                selectedOption.question_option_mapping_versions.some(
                  (optionMapping) =>
                    parseInt(optionMapping.external_field_identifier) ===
                    lawyawOption.id,
                ),
              );
              if (selectedOption !== undefined) {
                responseCollection.push(lawyawOption.value);
              }
            });
          } else {
            // a single-select question maps a text field
            if (selectedOptions && selectedOptions[0]) {
              responseCollection.push(selectedOptions[0].label);
            }
          }

          responseDict[questionMapping.external_field_identifier] =
            responseCollection[0] ?? '';
          break;
        }

        case 'QuestionSubmissionMultipleChoiceMulti': {
          let responseCollection: string[] = [];

          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );

          const questionMapping =
            questionVersion.question_mapping_versions.length > 0
              ? questionVersion.question_mapping_versions[0]
              : undefined;
          if (questionMapping === undefined) {
            break;
          }
          // tagStr is {Entity.id}.{Attribute.id} or {normalized_entity}.{normalized_attribute}
          const tagStr = questionMapping.external_field_identifier;
          const lawyawOptions = attributeTagToOptionsMap[tagStr];

          if (lawyawOptions !== undefined && lawyawOptions.length > 0) {
            // a multi-select question maps a multi-select field
            lawyawOptions.forEach((lawyawOption: any) => {
              const selectedOption = selectedOptions.find((selectedOption) =>
                selectedOption.question_option_mapping_versions.some(
                  (optionMapping) =>
                    parseInt(optionMapping.external_field_identifier) ===
                    lawyawOption.id,
                ),
              );
              if (selectedOption !== undefined) {
                responseCollection.push(lawyawOption.value);
              } else {
                // need to include empty strings for each option that was not selected specifically
                // for multiselect.  This is how Lawyaw's populate page works.
                responseCollection.push('');
              }
            });
          } else {
            // a multi-select question maps a text field
            responseCollection = selectedOptions.map(
              (selectedOptions) => selectedOptions.label,
            );
          }

          responseDict[questionMapping.external_field_identifier] =
            responseCollection.join(', ');
          break;
        }
      }
    }
  });

  return responseDict;
};

export const tryConvertToNumber = (str: string): number | string => {
  const num = Number(str);

  return isNaN(num) ? str : num;
};
