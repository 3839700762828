import React, { useState, useEffect, ReactNode } from 'react';
import { useObserver } from 'mobx-react';

import { useStore } from '~/src/hooks/useMst';

import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

import useDocumentsActions from '~/src/hooks/useDocumentsActions';
import useProject from '~/src/hooks/useProject';
import { useLayoutContext } from '~/src/contexts/Layout';
import { DeleteIconButton } from './DeleteIconButton';
import { RenameIconButton } from './RenameIconButton';
import { DuplicateIconButton } from './DuplicateIconButton';
import { LimitWarning } from './LimitWarning';
import { Empty } from './Empty';
import { OrderableList } from '../OrderableList';
import { SelectionSidebarItem } from './SelectionSidebarItem';
import projectsService from '~/src/services/api/projectsService';

import { Instance } from 'mobx-state-tree';
import SidebarItemsStore from '~/src/stores/sidebarItemsStore';
import { TOTAL_DOCUMENT_LIMIT } from '~/src/utils/constants';
import { useCurrentOrgFprint } from '~/src/entities/user';

type SidebarItem = {
  id: number | string;
  title: string;
  subtitle: string | null;
  sortOrder: number;
  defaultValueId: number | null;
  globalTemplateId: number | null;
  templateId: number | null;
};

type SidebarStore = Instance<typeof SidebarItemsStore>;

export type SelectionSidebarProps = {
  isDrafting?: boolean;
  renderFooter?: (items: ReturnType<SidebarStore['sortedItems']>) => ReactNode;
  renderHeader?: (items: ReturnType<SidebarStore['sortedItems']>) => ReactNode;
};

const SelectionSidebar = ({
  isDrafting,
  renderFooter,
  renderHeader,
}: SelectionSidebarProps) => {
  const orgFprint = useCurrentOrgFprint();
  const { projects, sidebarItems } = useStore();

  const projectId = useObserver(() => projects.curProjectId);
  const items = useObserver(() => sidebarItems.sortedItems());
  const currentIndex = useObserver(() => sidebarItems.currentIndex);
  const totalNumber = items.length;
  const {
    reOrder,
    remove: removeSidebarItem,
    setIdentifier,
    setIndex: setCurrentIndex,
  } = sidebarItems;

  const { showToast } = useLayoutContext();

  const {
    renameProjectDocument: handleRenameDocument,
    deleteProjectDocument: deleteDocument,
    duplicateDocument: handleDuplicateDocument,
  } = useDocumentsActions(isDrafting);

  // useProject hook is responsible for re-rendering sidebar based on updates to the project
  useProject(isDrafting);

  const [limitReachedWarningDismissed, setLimitReachedWarningDismissed] =
    useState(false);

  useEffect(() => {
    const currentItem = sidebarItems.items.find(
      (item) => item.sortOrder + 1 === currentIndex, // currentIndex is indexed starting at 1
    );
    if (currentItem) {
      setIdentifier(currentItem.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  if (isDrafting && totalNumber === 0) return null;

  const shouldShowLimitWarning =
    totalNumber >= TOTAL_DOCUMENT_LIMIT && !limitReachedWarningDismissed;

  const handleChangeOrder = async (items: SidebarItem[]) => {
    reOrder(items);
    if (!projectId) return;
    await projectsService.orderDocuments(
      orgFprint,
      projectId,
      items.map(({ id }) => id),
    );
    projects.fetchProject(projectId, false);
  };

  return (
    <div
      id="selection_sidebar"
      className="bg-grey-light bg-white border-l relative h-full flex flex-col justify-between"
    >
      <div className="overflow-auto">
        {renderHeader?.(items)}

        {totalNumber === 0 ? (
          <Empty />
        ) : (
          <>
            {shouldShowLimitWarning ? (
              <LimitWarning
                onDismiss={() => setLimitReachedWarningDismissed(true)}
              />
            ) : null}
            <OrderableList
              onChangeOrder={handleChangeOrder}
              items={items}
              id={({ id }) => `${id}`}
              className={'p-3 pb-20'}
              render={(item, index) => (
                <SelectionSidebarItem
                  label={`${item.title} ${item.subtitle || ''}`}
                  selected={
                    items.findIndex(
                      ({ sortOrder }) => sortOrder + 1 === currentIndex,
                    ) === index
                  }
                  onClick={() => {
                    setIdentifier(item.id);
                    setCurrentIndex(index + 1);
                  }}
                  actions={
                    <>
                      {item.templateId && isDrafting && (
                        <RenameIconButton
                          onClick={() => handleRenameDocument(item)}
                        />
                      )}
                      {item.templateId && isDrafting && (
                        <DuplicateIconButton
                          onClick={() => {
                            if (totalNumber >= TOTAL_DOCUMENT_LIMIT) {
                              // in this case, we don't want users to reach the duplication modal, so we will show an error modal
                              showToast(LAYOUT_TOAST_TYPES.error, {
                                toastDelay: 7000,
                                message: `Can't duplicate, exceeds document count limit of 20`,
                              });
                              return null;
                            }

                            handleDuplicateDocument(item);
                          }}
                        />
                      )}
                      {(!isDrafting || totalNumber > 1) && (
                        <DeleteIconButton
                          onClick={() => {
                            if (isDrafting) {
                              deleteDocument(item);
                            } else removeSidebarItem(item);

                            setLimitReachedWarningDismissed(false);
                          }}
                        />
                      )}
                    </>
                  }
                />
              )}
            />
          </>
        )}
      </div>

      {renderFooter?.(items)}
    </div>
  );
};

export default SelectionSidebar;
