import * as React from 'react';
import { ReactComponent as EmptyViewIcon } from './empty-view.svg';

export const Empty = () => {
  return (
    <div className="p-6 pt-20">
      <div className="flex justify-center">
        <EmptyViewIcon />
      </div>
      <div className="pt-6 text-center font-semibold">
        No documents selected
      </div>
      <div className="pt-3 text-center text-sm">
        Add templates or sets that you want to draft from the left side.
      </div>
    </div>
  );
};
