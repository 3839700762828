import { types, flow, getParent } from 'mobx-state-tree';
import { OrganizationMember } from './organizationMember';
import membershipService from '~/src/services/membership';
import _ from 'lodash';
import { mapOrganizationInvoice } from '~/src/utils/dataTransformers';

import {
  OrganizationReceipt,
  OrganizationInvoice,
  OrganizationCoupon,
  OrganizationSubscription,
} from './subscriptionStore';
import { ERROR_STATE, PENDING_STATE, SUCCESS_STATE } from './states';

export const OrganizationStore = types
  .model({
    uid: types.identifier,
    id: types.number,
    admin: types.boolean,
    subscriptionId: types.maybeNull(types.string),
    email: types.string,
    initials: types.string,
    isDefault: types.boolean,
    enableDevTools: types.optional(types.boolean, false),
    isNonProfit: types.optional(types.boolean, false),
    organizationName: types.string,
    signupSource: types.maybeNull(types.string),
    avatar: types.maybeNull(types.string),
    displayName: types.string,
    fprint: types.string,
    clioIntegrationEnabled: types.optional(types.boolean, false),
    clioLastSync: types.maybeNull(types.string),
    orgId: types.maybeNull(types.number),
    state: types.optional(types.string, SUCCESS_STATE),
    members: types.optional(types.array(OrganizationMember), []),
    invoices: types.optional(types.array(OrganizationInvoice), []),
    receipts: types.optional(types.array(OrganizationReceipt), []),
    subscription: types.maybeNull(OrganizationSubscription),
    coupon: types.optional(OrganizationCoupon, {}),
    error: types.maybeNull(types.string),
    numFreeLicenses: types.optional(types.number, 0),
    upcomingSeatPrice: types.optional(types.number, 0),
  })
  .actions((self) => {
    const fetchSeatInvoice = flow(function* fetchSeatInvoice(seats) {
      try {
        const res = yield membershipService.fetchSeatInvoice(
          self.fprint,
          seats,
        );
        self.upcomingSeatPrice = res.total;
        return res;
      } catch (error) {
        console.error('Error fetching seat invoice : ', error);
        return false;
      }
    });

    const fetchUpcomingInvoice = flow(function* fetchUpcomingInvoice(
      add_plans,
      remove_plans,
      one_time_charges,
      duration,
      seat,
      coupon,
    ) {
      let freeTrialActive = false;
      if (self.subscription && self.subscription.freeTrialIsActive()) {
        freeTrialActive = true;
      }

      try {
        const res = yield membershipService.fetchUpcomingInvoice(
          self.fprint,
          add_plans,
          remove_plans,
          one_time_charges,
          duration,
          seat,
          coupon,
          freeTrialActive,
        );
        return res;
      } catch (error) {
        console.error('Error fetching upcoming invoice : ', error);
        return false;
      }
    });

    const applyCoupon = flow(function* applyCoupon(coupon) {
      try {
        const res = yield membershipService.applyCoupon(self.fprint, coupon);

        if (res && res.percent_off) {
          return res;
        }

        return false;
      } catch (error) {
        console.error('Something went wrong applying coupon', error);
        return false;
      }
    });

    const updateSubscription = flow(function* updateSubscription(properties) {
      self.state = PENDING_STATE;
      try {
        const previousSubscription = yield self.fetchSubscription();
        const res = yield membershipService.updateSubscription(
          self.fprint,
          properties,
        );
        yield self.fetchSubscription();
        self.state = SUCCESS_STATE;
        self.error = null;
        return { nextSubscription: res, previousSubscription };
      } catch (error) {
        console.error('Failed to update subscription : ', error);
        if (typeof error === 'string') {
          self.error = error;
        }
        self.state = ERROR_STATE;
      }
    });

    const createSubscription = flow(function* createSubscription({
      card,
      plan,
      seats,
      coupon,
    }) {
      try {
        const res = yield membershipService.createSubscription(self.fprint, {
          card,
          plan,
          seats,
          coupon,
        });
        return {
          plan,
          seats,
          coupon,
          ...res,
        };
      } catch (error) {
        self.state = ERROR_STATE;
        self.error =
          typeof error === 'string'
            ? error
            : 'Something went wrong, please try again later';
        console.error('Failed to create subscription ', error);
      }

      return false;
    });

    const createSubscription_V2 = flow(function* createSubscription_V2({
      card,
      add_plans,
      remove_plans,
      one_time_charges,
      duration,
      seats,
      coupon,
    }) {
      let freeTrialActive = false;
      let interval_change = false;

      if (self.subscription && self.subscription.freeTrialIsActive()) {
        freeTrialActive = true;
        if (duration === 'annual') {
          interval_change = true; // free-trial's duration is 'monthly'
        }
      }

      try {
        const res = yield membershipService.createSubscription_V2(self.fprint, {
          card,
          add_plans,
          remove_plans,
          one_time_charges,
          duration,
          seats,
          coupon,
          freeTrialActive,
          interval_change,
        });
        return {
          duration,
          seats,
          coupon,
          ...res,
        };
      } catch (error) {
        self.state = ERROR_STATE;
        self.error =
          typeof error === 'string'
            ? error
            : 'Something went wrong, please try again later';
        console.error('Failed to create subscription ', error);
      }

      return false;
    });

    const fetchCoupons = flow(function* fetchCoupons() {
      self.state = PENDING_STATE;
      try {
        const res = yield membershipService.fetchCoupons(self.fprint);
        self.coupon = res;
        self.state = SUCCESS_STATE;
        return res;
      } catch (error) {
        console.error('Failed to fetch subscription coupons', error);
        self.state = ERROR_STATE;
      }
    });

    const fetchSubscription = flow(function* fetchSubscription() {
      self.state = PENDING_STATE;
      try {
        const res = yield membershipService.fetchSubscription(self.fprint);

        if (res.plan) {
          self.subscription = res;
        }

        if (res && res.paidQuantity) {
          const invoiceRes = yield self.fetchSeatInvoice(res.paidQuantity);
          if (invoiceRes) {
            const invoice = mapOrganizationInvoice(invoiceRes);
            self.subscription.nextInvoice = invoice;
          }
        }

        self.state = SUCCESS_STATE;
        return res;
      } catch (error) {
        console.error('Failed to fetch subscription ', error);
        self.state = ERROR_STATE;
      }
    });

    const fetchInvoices = flow(function* fetchInvoices() {
      self.state = PENDING_STATE;
      try {
        const res = yield membershipService.fetchInvoices(self.fprint);
        self.invoices = res;
        self.state = SUCCESS_STATE;
      } catch (error) {
        console.error('Failed to fetch invoices ', error);
        self.state = ERROR_STATE;
      }
    });

    const fetchReceipts = flow(function* fetchReceipts() {
      self.state = PENDING_STATE;
      try {
        const res = yield membershipService.fetchReceipts(self.fprint);
        self.receipts = res;
        self.state = SUCCESS_STATE;
      } catch (error) {
        console.error('Failed to fetch receipts ', error);
        self.state = ERROR_STATE;
      }
    });

    const changeMemberRole = flow(function* changeMemberRole(memberId, role) {
      const member = _.find(self.members, ['id', parseInt(memberId)]);

      member.setRole(role);

      try {
        yield membershipService.updateMemberRole(self.fprint, memberId, role);
      } catch (error) {
        console.error('Something went wrong changing member role', error);
      }
    });

    const removeMember = flow(function* removeMember(memberId) {
      try {
        yield membershipService.removeMember(self.fprint, memberId);
        self.members = _.filter(self.members, (member) => {
          return member.id !== parseInt(memberId);
        });
        return true;
      } catch (error) {
        console.error('Something went wrong ', error);
        return false;
      }
    });

    const resendMemberInvitation = flow(function* resendMemberInvitation(
      memberId,
    ) {
      try {
        yield membershipService.resendMemberInvite(self.fprint, memberId);
        return true;
      } catch (error) {
        console.error('Something went wrong resendMemberInvitation', error);
        return false;
      }
    });

    const revokeMemberInvitation = flow(function* (memberId) {
      try {
        yield membershipService.revokeMemberInvite(self.fprint, memberId);
        self.members = _.filter(self.members, (member) => {
          return member.id !== parseInt(memberId);
        });
        return self.members;
      } catch (error) {
        console.error('Something went wrong revokeMemberInvitation', error);
        return false;
      }
    });

    const fetchMembers = flow(function* fetchMembers() {
      self.state = PENDING_STATE;
      try {
        const members = yield membershipService.fetchMembers(self.fprint);
        self.members = members;
        self.state = SUCCESS_STATE;
      } catch (error) {
        console.error('Failed to fetch members ', error);
        self.state = ERROR_STATE;
      }
    });

    const inviteUsers = flow(function* inviteUsers(emails, isAdmin) {
      self.state = PENDING_STATE;

      try {
        yield membershipService.inviteUsers(self.fprint, emails, isAdmin);
        yield self.fetchMembers();
        self.state = SUCCESS_STATE;
        return emails;
      } catch (error) {
        self.state = ERROR_STATE;
        return false;
      }
    });

    const update = flow(function* update(properties) {
      self.state = PENDING_STATE;

      try {
        yield membershipService.updateOrganization(self.fprint, {
          name: properties.organizationName,
        });

        Object.keys(properties).forEach((key) => {
          if (typeof self[key] !== 'undefined') {
            self[key] = properties[key];
          }
        });

        self.state = SUCCESS_STATE;
        return self;
      } catch (error) {
        console.error(`Someting went wrong updating organization`, error);
        self.state = ERROR_STATE;
        return false;
      }
    });

    const setAsDefault = flow(function* setAsDefault() {
      self.state = PENDING_STATE;
      try {
        yield membershipService.setAsDefault(self.fprint);
        getParent(self, 2).setDefaultOrg(self.id);
        self.isDefault = true;
        self.state = SUCCESS_STATE;
      } catch (error) {
        console.error('Error setting organization as default', error);
        self.state = ERROR_STATE;
      }
    });

    return {
      applyCoupon,
      fetchInvoices,
      fetchReceipts,
      fetchMembers,
      fetchSubscription,
      fetchCoupons,
      fetchSeatInvoice,
      fetchUpcomingInvoice,
      update,
      setAsDefault,
      inviteUsers,
      changeMemberRole,
      removeMember,
      resendMemberInvitation,
      revokeMemberInvitation,
      createSubscription,
      createSubscription_V2,
      updateSubscription,
    };
  })
  .views((self) => {
    const isLoading = () => {
      return self.state === PENDING_STATE;
    };

    const isSubscribed = () => {
      return !!self.subscription;
    };

    const getTotalSeats = () => {
      let totalSeats = 0;
      if (self.subscription) {
        totalSeats += self.subscription.quantity;
      }

      totalSeats += self.numFreeLicenses;

      return totalSeats;
    };

    return {
      isSubscribed,
      isLoading,
      getTotalSeats,
    };
  });
