import React from 'react';
import { NavLink } from 'react-router-dom';

import useFeatureFlags from '~/src/hooks/useFeatureFlags';

const renderPaidSection = () => (
  <div className="text-center mt-2">
    You can create a new template using the{' '}
    <a
      href="https://appsource.microsoft.com/en-us/product/office/WA104381799?tab=Overview"
      target="_blank"
      rel="noreferrer"
    >
      Clio Draft Word add-in found here.
    </a>
  </div>
);

const renderNonPaidSection = () => (
  <div className="text-center mt-3">
    <NavLink to={'/settings/subscriptions'}>Upgrade now</NavLink>
  </div>
);

const TemplatesEmptyView = () => {
  const { isWordDocumentAutomationEnabled } = useFeatureFlags();

  return (
    <div className="mx-6">
      <div className="text-center mt-12 text-lg font-medium">
        Automate your Word documents
      </div>

      <div className="text-center mt-2">
        Turn your custom Word documents into fillable cloud-based templates.
      </div>

      {isWordDocumentAutomationEnabled && renderPaidSection()}

      {!isWordDocumentAutomationEnabled && renderNonPaidSection()}
    </div>
  );
};

export default TemplatesEmptyView;
