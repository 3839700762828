import React, { useEffect, useState } from 'react';
import {
  QuestionnaireBuilderApp,
  type Questionnaire,
  TabType,
} from '@clio/questionnaire-builder';
import { QuestionnaireBuilderApp as DevQuestionnaireBuilderApp } from '@clio/questionnaire-builder-dev';
import { history } from '~/src/utils/history';
import {
  useGetDocumentFields,
  useUpdateClioQuestionnaire,
  useGetClioQuestionnaireById,
  useGetQuestionnaireTemplates,
  useGetClioQuestionnaireUrl,
  tryConvertToNumber,
  useUploadLogo,
  useRemoveLogo,
  useGetElement,
} from '~/src/entities/questionnaires';
import Page, { LoadingOverlay } from '~/src/components/PageLayout';
import { useSnackbar } from 'notistack';
import { useCurrentOrgFprint } from '~/src/entities/user';
import '@clio/questionnaire-builder/dist/style.css';
import analyticsService from '~/src/services/analytics';
import { ShareQuestionnaireTab } from '~/src/entities/questionnaires/components/ShareQuestionnaireTab';
import env from '~/src/utils/env';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';

export const QuestionnaireBuilderPage = ({
  questionnaireId,
  isAutoGenerated,
  hasAutoGenerateErrors,
}: {
  questionnaireId: string;
  isAutoGenerated?: boolean;
  hasAutoGenerateErrors?: boolean;
}) => {
  const snackbar = useSnackbar();
  const orgFprint = useCurrentOrgFprint();

  const {
    data: questionnaireTemplates,
    isLoading: isTemplatesLoading,
    isFetching: isTemplatesFetching,
    isError: isTemplatesError,
  } = useGetQuestionnaireTemplates(orgFprint, questionnaireId);

  const {
    data: documents,
    isError: isDocumentMappingError,
    isLoading: isDocumentMappingLoading,
    mutate: getDocumentFields,
  } = useGetDocumentFields(orgFprint);

  const { data: questionnaire, isError: isQuestionnaireError } =
    useGetClioQuestionnaireById(orgFprint, questionnaireId);

  // move churnzero button away from feedback button.
  const element = useGetElement('#cz_success_center_container');

  useEffect(() => {
    if (!element) return;

    element.setAttribute(
      'style',
      'top: 0px !important; right: 235px !important',
    );

    return () => {
      if (!element) return;

      // set the churnzero button back to its original position
      element.setAttribute(
        'style',
        'top: 4px !important; right: 74px !important',
      );
    };
  }, [element]);

  const updateClioQuestionnaire = useUpdateClioQuestionnaire(orgFprint);
  const uploadLogo = useUploadLogo(orgFprint);
  const removeLogo = useRemoveLogo(orgFprint);

  const handleSaveQuestionnaire = async (questionnaire: Questionnaire) =>
    await updateClioQuestionnaire.mutateAsync(
      {
        questionnaireId,
        clioQuestionnaire: { ...questionnaire },
      },
      {
        onError: async () => {
          snackbar.enqueueSnackbar(
            'Something went wrong, please try again later.',
            {
              variant: 'error',
            },
          );
        },
      },
    );

  const handleClose = () => {
    history.push(`/questionnaires/`);
  };

  useEffect(() => {
    if (!questionnaireTemplates) return;

    getDocumentFields({
      templateIds:
        questionnaireTemplates.map((template) =>
          String(template.template_id),
        ) ?? [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireTemplates]);

  const [matterId, setMatterId] = useState<null | string>(null);
  const [formValid, setFormValid] = useState(false);
  const [isLinkGenerated, setLinkGenerated] = useState<boolean>(false);
  const [rerender, setRerender] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState<TabType>(TabType.Create);

  const useDevelopmentQuestionnaires = useUIFlagEnabled(
    'developmentQuestionnaires',
  );

  const {
    data: shareData,
    mutate: shareLink,
    isError: isLinkError,
    isLoading: isLinkLoading,
  } = useGetClioQuestionnaireUrl();

  const handleGenerateLink = () => {
    if (!matterId) return;

    shareLink({ orgFprint, questionnaireId, matterId });

    analyticsService.track('Clicked on Generate Share Link', {
      questionnaireId,
      matterId,
      page: 'questionnaire_builder_page',
    });

    setLinkGenerated(true);
  };

  const handleGenerateAnotherLink = () => {
    setLinkGenerated(false);
    setFormValid(false);
    setMatterId(null);
    setRerender(rerender + 1);
  };

  const handleViewResponses = () => {
    if (!matterId || !shareData) return;

    const submissionId = shareData.submission_id;

    analyticsService.track('Clicked on Live Interview', {
      questionnaire_id: questionnaireId,
      submission_id: submissionId,
      matter_id: tryConvertToNumber(matterId),
    });
    window.open(
      env.basename +
        `/questionnaires/${questionnaireId}/responses/${submissionId}`,
      '_blank',
    );
  };

  if (isDocumentMappingError || isQuestionnaireError || isTemplatesError) {
    snackbar.enqueueSnackbar(
      'Error, Failed to load Interview Builder. Please try again later.',
      {
        variant: 'error',
      },
    );
    history.push(`/questionnaires/`);

    return <></>;
  }

  if (!isTemplatesFetching && questionnaireTemplates?.length === 0) {
    history.push(
      `/questionnaires/${questionnaireId}/select-documents/template-sets`,
    );
    return <></>;
  }

  if (
    questionnaire &&
    documents &&
    !isDocumentMappingLoading &&
    !isTemplatesLoading
  ) {
    const filteredSections = questionnaire.sections_attributes.filter((s) =>
      s._destroy != null ? !s._destroy : true,
    );

    const isShareable = filteredSections.some(
      (section) =>
        section.questions_attributes.filter((q) =>
          q._destroy != null ? !q._destroy : true,
        ).length !== 0,
    );

    if (useDevelopmentQuestionnaires) {
      return (
        <Page disablePadding={true} showNavigation={false} showHeader={false}>
          <DevQuestionnaireBuilderApp
            onExit={handleClose}
            documents={documents}
            questionnaire={questionnaire}
            onSave={handleSaveQuestionnaire}
            onAnalyticsEvent={(event: any) => {
              analyticsService.track(event.name, event.properties);
            }}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            shareTabContents={
              <ShareQuestionnaireTab
                key={rerender} // simple way to clear the form
                questionnaireId={questionnaireId}
                questionnaireTitle={questionnaire.title}
                isDirty={questionnaire['dirty?'] ?? false}
                matterId={matterId}
                setMatterId={setMatterId}
                setFormValid={setFormValid}
                questionnaireLink={shareData?.link}
                isLinkError={isLinkError}
                isLinkLoading={isLinkLoading}
                isLinkGenerated={isLinkGenerated}
                setLinkGenerated={setLinkGenerated}
                isShareable={isShareable}
                setCurrentTab={setCurrentTab}
              />
            }
            shareTabFooterButtonProps={{
              onGenerateLink: handleGenerateLink,
              onGenerateAnotherLink: handleGenerateAnotherLink,
              onViewResponses: handleViewResponses,
              isLinkGenerated,
              isLinkReadyToBeGenerated: matterId !== null && formValid,
            }}
            styleTabProps={{
              onLogoUpload: async (logo: File) => {
                await uploadLogo.mutateAsync({
                  questionnaireId,
                  logo,
                });
              },
              onLogoRemove: async () => {
                await removeLogo.mutateAsync({
                  questionnaireId,
                });
              },
            }}
            hasAutoGenerateErrors={hasAutoGenerateErrors ? true : false}
          />
        </Page>
      );
    }

    return (
      <Page disablePadding={true} showNavigation={false} showHeader={false}>
        <QuestionnaireBuilderApp
          onExit={handleClose}
          documents={documents}
          questionnaire={questionnaire}
          onSave={handleSaveQuestionnaire}
          onAnalyticsEvent={(event: any) => {
            analyticsService.track(event.name, event.properties);
          }}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          shareTabContents={
            <ShareQuestionnaireTab
              key={rerender} // simple way to clear the form
              questionnaireId={questionnaireId}
              questionnaireTitle={questionnaire.title}
              isDirty={questionnaire['dirty?'] ?? false}
              matterId={matterId}
              setMatterId={setMatterId}
              setFormValid={setFormValid}
              questionnaireLink={shareData?.link}
              isLinkError={isLinkError}
              isLinkLoading={isLinkLoading}
              isLinkGenerated={isLinkGenerated}
              setLinkGenerated={setLinkGenerated}
              isShareable={isShareable}
              setCurrentTab={setCurrentTab}
            />
          }
          shareTabFooterButtonProps={{
            onGenerateLink: handleGenerateLink,
            onGenerateAnotherLink: handleGenerateAnotherLink,
            onViewResponses: handleViewResponses,
            isLinkGenerated,
            isLinkReadyToBeGenerated: matterId !== null && formValid,
          }}
          styleTabProps={{
            onLogoUpload: async (logo: File) => {
              await uploadLogo.mutateAsync({
                questionnaireId,
                logo,
              });
            },
            onLogoRemove: async () => {
              await removeLogo.mutateAsync({
                questionnaireId,
              });
            },
          }}
        />
      </Page>
    );
  }

  return isAutoGenerated ? (
    <LoadingOverlay
      title="Generating questions from your documents..."
      relative={false}
    />
  ) : (
    <LoadingOverlay title="Loading Interview Builder..." relative={false} />
  );
};
