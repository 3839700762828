/* Libraries */
import { throttle } from 'lodash';

/* Services */
import projectsService from '~/src/services/api/projectsService';

/* Utilities */
import {
  mapGroupFields,
  mapProjectDocuments,
} from '~/src/utils/dataTransformers';

function projects() {
  const populateProject = async (orgFprint, projectId) => {
    return projectsService.populateProject(orgFprint, projectId);
  };
  const updateProject = async (
    orgFprint,
    projectId,
    variables,
    projectData,
    currentStackData,
  ) => {
    return projectsService.updateProject(
      orgFprint,
      projectId,
      variables,
      projectData,
      currentStackData,
    );
  };

  const fetchProject = async (orgFprint, projectId, getPDFElements) => {
    const project = await projectsService.fetchProject(
      orgFprint,
      projectId,
      getPDFElements,
    );
    const stackData = project.stack_data;
    const stackSavedData = project.stack_saved_data;
    const documents = mapProjectDocuments(
      stackData.documents || project.documents,
    );
    const cards = mapGroupFields(stackData.groups, stackData.fields, documents);
    return {
      id: project.id,
      identifier: `document:${project.id}`,
      clientId: project.client_id,
      matterId:
        (stackData.project_metadata && stackData.project_metadata.matter_id) ||
        null,
      clioMatterId: project.clio_matter_id,
      clioParentId: project.clio_parent_id,
      fprint: project.fprint,
      documents,
      name: project.title,
      stackSavedData,
      cards,
      updatedAt: project.last_modified,
    };
  };

  const duplicateDocument = async (
    orgFprint,
    projectId,
    document,
    numberOfDuplicates,
  ) => {
    const project = await projectsService.duplicateDocument(
      orgFprint,
      projectId,
      numberOfDuplicates,
      document,
    );

    const stackData = project.stack_data;
    const stackSavedData = project.stack_saved_data;
    const documents = mapProjectDocuments(
      stackData.documents || project.documents,
    );
    const cards = mapGroupFields(stackData.groups, stackData.fields, documents);
    return {
      id: project.id,
      identifier: `document:${project.id}`,
      clientId: project.client_id,
      matterId:
        (stackData.project_metadata && stackData.project_metadata.matter_id) ||
        null,
      fprint: project.fprint,
      documents,
      name: project.title,
      stackSavedData,
      cards,
    };
  };

  const updateProjectDocuments = async (orgFprint, projectId, documents) => {
    const response = await projectsService.updateProjectDocuments(
      orgFprint,
      projectId,
      documents,
    );

    return response;
  };

  const createDocSet = async (
    orgFprint,
    templateIds,
    name,
    matterId,
    version,
  ) => {
    const res = await projectsService.createProject(
      orgFprint,
      templateIds,
      name,
      matterId,
      version,
    );
    if (res.task_id) {
      return res;
    } else {
      const documents = mapProjectDocuments(
        res.stack_data.documents || res.documents,
      );
      const cards = mapGroupFields(
        res.stack_data.groups,
        res.stack_data.fields,
        documents,
      );
      return {
        id: res.id,
        identifier: `document:${res.id}`,
        clientId: res.client_id,
        matterId:
          (res.stack_data.project_metadata &&
            res.stack_data.project_metadata.matter_id) ||
          null,
        fprint: res.fprint,
        documents,
        name: res.title,
        stackSavedData: res.stack_saved_data,
        cards,
        updatedAt: res.last_modified,
      };
    }
  };

  const getQuestionnaireFormProjectData = async (projectFprint) => {
    const res = await projectsService.getQuestionnaireFormProjectData(
      projectFprint,
    );
    const documents = mapProjectDocuments(res.documents);
    const cards = mapGroupFields(res.groups, res.fields, documents);
    return {
      id: res.project_metadata.id,
      identifier: `document:${res.project_metadata.id}`,
      matterId: res.project_metadata.matter_id,
      clientId: res.client.id,
      fprint: projectFprint,
      documents,
      name,
      cards,
    };
  };

  const getProjectData = async (orgFprint, projectId, name) => {
    const res = await projectsService.getClientProjectMetaData(
      orgFprint,
      projectId,
    );

    const documents = mapProjectDocuments(res.documents);
    const cards = mapGroupFields(res.groups, res.fields, documents);

    return {
      id: projectId,
      identifier: `document:${projectId}`,
      matterId: res.project_metadata.matter_id,
      clientId: res.client_id,
      fprint: res.client_fprint,
      documents,
      name,
      cards,
    };
  };

  const downloadDocSet = async (fprint, removeHighlights) => {
    const res = await projectsService.downloadDocSet(fprint, removeHighlights);
    const data = await res.arrayBuffer();
    return new Blob([data], {
      type: 'application/octet-stream',
    });
  };

  const deleteProject = async (orgFprint, projectId) => {
    return projectsService.deleteProject(orgFprint, projectId);
  };

  return {
    getProjectData,
    fetchProject,
    duplicateDocument,
    updateProjectDocuments,
    // getClientProject,
    getQuestionnaireFormProjectData,
    downloadDocSet,
    deleteProject,
    populateProject,
    updateProject: throttle(updateProject, 500),

    createDocSet,
  };
}

export default projects();
