/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { css } from 'aphrodite';

/* Containers */

import QuestionnaireResponsesContainer from '~/src/containers/questionnaires/QuestionnaireResponses';

/* Components */
import Card from '~/src/components/Card';
import { Form } from '~/src/components/Forms';
import { Small } from '~/src/components/Text';
import { Row } from '~/src/components/PageLayout/Layout';
import SingleMatterSync from '~/src/components/SingleMatterSync';
import Documents from '~/src/components/Documents';
import DocumentSets from '~/src/components/DocumentSets';
import ClioQuestionnaireSubmissions from '~/src/components/ClioQuestionnaireSubmissions';
import {
  mapDocumentToTable,
  mapDocumentSetToTable,
} from '~/src/utils/dataTransformers';

/* Styles */
import styles from './styles';

const MatterDetail = ({
  summaryFields,
  documents,
  documentSets,
  intakes,
  relatedContactsFields,
  clioCustomFields,
  entityCardFields,
  isQuestionnairesEnabled,
  matter,
  isClioQuestionnaireEnabled,
  clioQuestionnaireSubmissions,
  clioQuestionnaireSubmissionUnmappedQuestions,
  onSynced,
}) => {
  const renderClioQuestionnaireSubmissionUnmappedQuestionList = () => (
    <Box>
      {clioQuestionnaireSubmissionUnmappedQuestions.map((d) => {
        return (
          <Box
            key={d.id}
            sx={{
              margin: '15px',
              padding: '5px 10px',
              border: '1px solid gray',
              borderRadius: '5px',
            }}
          >
            <Box sx={{ marginBottom: '5px' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                {d.question}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: '5px' }}>
              <Typography sx={{ fontSize: '12px' }}>{d.response}</Typography>
            </Box>
            <Box sx={{ marginBottom: '5px', display: 'flex' }}>
              <Box>
                <Typography sx={{ fontSize: '10px', color: 'gray' }}>
                  created at: {moment(d.created_at).format('MM/DD/YYYY')}
                </Typography>
              </Box>

              <Box sx={{ margin: '0 5px', fontSize: '10px' }}>|</Box>

              <Box>
                <a
                  href={`/clients/questionnaires/${d.questionnaire_id}/responses/${d.questionnaire_submission_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography sx={{ fontSize: '10px', color: 'blue' }}>
                    View interview
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Row>
      <div className={css(styles.column)}>
        {matter.externalMatterId ? (
          <SingleMatterSync
            analyticsEventName="Matter Detail"
            onSynced={onSynced}
            matter={matter}
          />
        ) : null}

        <Card
          elevate
          dynamic
          cssStyle={styles.card}
          renderHeader={() => 'Summary'}
        >
          <Form fields={summaryFields} />
        </Card>

        {isQuestionnairesEnabled && (
          <Card
            evlevate
            dynamic
            noPadding={intakes.length > 0}
            cssStyle={styles.card}
            renderHeader={() => 'Submitted Questionnaires'}
          >
            {intakes.length > 0 ? (
              <QuestionnaireResponsesContainer
                intakes={intakes}
                columnWidths={['40%', '20%']}
                hideHeader
                noBorder
              />
            ) : (
              <Small>
                There are no questionnaire responses attached to this matter.
                Publish a questionnaire and assign it to this matter to view
                submitted questionnaires.
              </Small>
            )}
          </Card>
        )}

        <Card
          elevate
          dynamic
          noPadding={documents.length > 0}
          cssStyle={styles.card}
          renderHeader={() => 'Documents'}
        >
          {documents.length <= 0 && (
            <Small>
              There are no documents attached to this matter. Select a template
              for the <Link to="/templates">list of templates</Link> and choose
              this matter in the following screen.
            </Small>
          )}
          {documents.length > 0 && (
            <Documents
              documents={documents.map(mapDocumentToTable)}
              visibleItems={documents.length}
              columnWidths={['40%', '20%']}
              hideHeader
              noBorder
            />
          )}
        </Card>

        <Card
          elevate
          dynamic
          noPadding={documentSets.length > 0}
          cssStyle={styles.card}
          renderHeader={() => 'Document sets'}
        >
          {documentSets.length <= 0 && (
            <Small>
              There are no document sets attached to this matter. Select a
              template for the <Link to="/templates">list of templates</Link>{' '}
              and choose this matter in the following screen.
            </Small>
          )}
          {documentSets.length > 0 && (
            <DocumentSets
              documentSets={documentSets.map(mapDocumentSetToTable)}
              visibleItems={documentSets.length}
              columnWidths={['40%', '20%']}
              hideHeader
              noBorder
            />
          )}
        </Card>

        {isClioQuestionnaireEnabled && (
          <Card
            elevate
            dynamic
            noPadding={clioQuestionnaireSubmissions.length > 0}
            cssStyle={styles.card}
            renderHeader={() => 'Live interviews'}
          >
            {clioQuestionnaireSubmissions.length <= 0 && (
              <Small>
                There are no live interviews attached to this matter.
              </Small>
            )}
            {clioQuestionnaireSubmissions.length > 0 && (
              <ClioQuestionnaireSubmissions
                clioQuestionnaireSubmissions={clioQuestionnaireSubmissions}
                visibleItems={clioQuestionnaireSubmissions.length}
                columnWidths={['40%', '20%']}
                hideHeader
                noBorder
              />
            )}
          </Card>
        )}
      </div>
      <div className={css(styles.divider)} />
      <div className={css(styles.column)}>
        {relatedContactsFields.length > 0 && (
          <Card
            key="related-contacts"
            elevate
            dynamic
            cssStyle={styles.card}
            renderHeader={() => 'Related Contacts'}
          >
            <Form fields={relatedContactsFields} />
          </Card>
        )}
        {clioCustomFields.length > 0 && (
          <Card
            key="clio-fields"
            elevate
            dynamic
            cssStyle={styles.card}
            renderHeader={() => 'Clio Fields'}
          >
            <Form fields={clioCustomFields} />
          </Card>
        )}
        {Object.keys(entityCardFields).map((entity) => {
          const label = startCase(entity);

          const fields = entityCardFields[entity];

          return (
            <Card
              elevate
              key={label}
              dynamic
              cssStyle={styles.card}
              renderHeader={() => label}
            >
              <Form fields={fields} />
            </Card>
          );
        })}

        {isClioQuestionnaireEnabled && (
          <Card
            key="unmapped-questions"
            elevate
            dynamic
            cssStyle={styles.card}
            renderHeader={() => 'Custom live interviews'}
          >
            <Typography sx={{ fontSize: '12px' }}>
              These responses are not connected to existing document fields.
            </Typography>

            {clioQuestionnaireSubmissionUnmappedQuestions &&
              renderClioQuestionnaireSubmissionUnmappedQuestionList()}
          </Card>
        )}
      </div>
    </Row>
  );
};

MatterDetail.defaultProps = {
  intakes: [],
  documents: [],
};

MatterDetail.propTypes = {
  intakes: PropTypes.array,
  documentSets: PropTypes.array,
};

export default MatterDetail;
