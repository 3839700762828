import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as DocumentIcon } from './ClipboardPaper.svg';
import Button from '~/src/components/Button';

export const EmptyTableMessage: React.FC<{
  onCreate: () => void;
}> = ({ onCreate }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        witdh: '100%',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          padding: '16px',
        }}
      >
        <DocumentIcon />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          gap: '8px',
          maxWidth: '392px',
        }}
      >
        <Typography variant="subheading">
          No interview templates yet.
        </Typography>
        <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
          Create an interview template to share with your clients and gather
          information to help populate legal documents.
        </Typography>
        <Button primary onClick={onCreate}>
          Create interview template
        </Button>
      </Box>
    </Box>
  );
};
