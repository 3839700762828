import { Collapse } from '@mui/material';
import * as React from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { CardListItem, CardListItemProps } from './CardListItem';

export type ExpandableCardListItemProps = {
  expanded?: boolean;
  defaultExpanded?: boolean;
  onChangeExpanded?: (expanded: boolean) => void;
} & CardListItemProps;

export const ExpandableCardListItem = ({
  expanded,
  defaultExpanded = false,
  children,
  onChangeExpanded,
  renderMeta,
  ...rest
}: ExpandableCardListItemProps) => {
  const [_expanded, setExpanded] = React.useState(defaultExpanded);
  const isExpanded = typeof expanded !== 'undefined' ? expanded : _expanded;
  const handleChangeExpanded = () => {
    setExpanded(!_expanded);
    onChangeExpanded?.(isExpanded);
  };

  return (
    <CardListItem
      {...rest}
      renderMeta={(hovered) => renderMeta?.(hovered || isExpanded)}
      onClick={handleChangeExpanded}
      icon={isExpanded ? <ChevronDown /> : <ChevronRight />}
    >
      <Collapse in={isExpanded}>{children}</Collapse>
    </CardListItem>
  );
};
