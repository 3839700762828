import React, { useState } from 'react';

import { Form, renderFormField } from '~/src/components/Forms';

import { Box, Button, Typography } from '@mui/material';
import { PreviouslyCreatedLink } from './PreviouslyCreatedLink';
import analyticsService from '~/src/services/analytics';
import { tryConvertToNumber } from '../../utils';
import { NoQuestionsMessage } from '../ShareQuestionnaireTab/SharedSubmission';
import { history } from '~/src/utils/history';
import { MessageDialog } from '~/src/components/Modal/mui';

export const SelectMatter: React.FC<{
  questionnaireId: string;
  page: 'questionnaire_builder_page' | 'questionnaire_list_page';
  isShareable: boolean;
  onClose: () => void;
  onGenerateLink: (matterId: string) => void;
}> = ({ questionnaireId, page, isShareable, onClose, onGenerateLink }) => {
  const [matterId, setMatterId] = useState<null | string>(null);
  const [formValid, setFormValid] = useState(false);

  const handleFormChange = (form: any) => {
    const matterId = form.fields?.matter.value;
    setMatterId(matterId);
    analyticsService.track('Selected Matter', {
      questionnaire_id: tryConvertToNumber(questionnaireId),
      matter_id: tryConvertToNumber(matterId),
      page,
    });
    setFormValid(form.valid);
  };

  const fields = [
    {
      id: 'matter',
      type: 'matterSelect',
      validation: ({ value }: { value: string }) =>
        !!value && value.trim().length > 0,
    },
  ];

  const fieldsProp = fields.map((field) => {
    return renderFormField(field);
  });

  return (
    <MessageDialog
      title={'Generate a link to share'}
      onClose={onClose}
      dialogActions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={!formValid}
            onClick={() => onGenerateLink(matterId!)}
          >
            Generate Link
          </Button>
        </>
      }
    >
      {!isShareable && (
        <Box paddingBottom={'16px'}>
          <NoQuestionsMessage
            onClick={() => {
              history.push(`/questionnaires/${questionnaireId}/builder`);
            }}
          />
        </Box>
      )}
      <Box display="flex" flexDirection={'column'} gap={'4px'} width={'400px'}>
        <Typography variant="label">Select a matter</Typography>
        <Box
          sx={[
            !isShareable && {
              pointerEvents: 'none',
              opacity: 0.5,
            },
          ]}
        >
          <Form
            fields={fieldsProp}
            onChange={handleFormChange}
            className={undefined}
            focusFirstInput={undefined}
            focusTimeout={undefined}
          />
          {matterId && (
            <PreviouslyCreatedLink
              questionnaireId={questionnaireId}
              matterId={matterId}
            />
          )}
        </Box>
      </Box>
    </MessageDialog>
  );
};
