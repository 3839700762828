import React, { useEffect } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { QuestionnaireTableCardList } from './QuestionnaireTableCardList';
import { type Questionnaire } from '@clio/questionnaire-builder';
import { ShareQuestionnaireLinkModal } from '~/src/entities/questionnaires/components/ShareQuestionnaireLinkModal';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { history } from '~/src/utils/history';
import {
  DeleteQuestionnaireDialogModal,
  QuestionnaireTemplate,
  getQuestionnaireTemplateUrlById,
  submissionQueryArgs,
  tryConvertToNumber,
  useClioQuestionnaires,
  useCreateClioQuestionnaire,
  useCreateQuestionnaireTemplate,
  useDeleteClioQuestionnaire,
  useDuplicateQuestionnaire,
  useUpdateClioQuestionnaire,
} from '~/src/entities/questionnaires';
import { useLayoutContext } from '~/src/contexts/Layout';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import usePageScroll from '~/src/hooks/usePageScroll';
import EmptyView from '~/src/components/EmptyView';
import { useQueryClient } from 'react-query';
import { useCurrentOrgFprint } from '~/src/entities/user';
import Button from '~/src/components/Button';
import analyticsService from '~/src/services/analytics';
import { Edit } from 'react-feather';
import { EmptyTableMessage } from './EmptyTableMessage';

export const QuestionnaireTable: React.FC = () => {
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);
  const [isWarnDeleteModalOpen, setIsWarnDeleteModalOpen] =
    React.useState(false);
  const [questionnaireId, setQuestionnaireId] = React.useState('');
  const [totalQuestions, setTotalQuestions] = React.useState(0);

  const orgFprint = useCurrentOrgFprint();

  const {
    data,
    isError,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useClioQuestionnaires(orgFprint);

  const createClioQuestionnaire = useCreateClioQuestionnaire(orgFprint);
  const createQuestionnaireTemplate = useCreateQuestionnaireTemplate();
  const updateClioQuestionnaire = useUpdateClioQuestionnaire(orgFprint);
  const deleteClioQuestionnaire = useDeleteClioQuestionnaire(orgFprint);
  const duplicateClioQuestionnaire = useDuplicateQuestionnaire(orgFprint);

  const { showToast, showModal, hideModal } = useLayoutContext();

  const pageScroll = usePageScroll();

  const client = useQueryClient();

  useEffect(() => {
    if ((pageScroll === 0 || pageScroll >= 85) && !isLoading) {
      fetchNextPage();
    }
  }, [fetchNextPage, isLoading, pageScroll]);

  useEffect(() => {
    client.removeQueries(submissionQueryArgs.lists());
  }, [client]);

  const onCreate = () => {
    const handleCreateQuestionnaireTemplate = async (form: {
      fields: { name: { value: string } };
    }) => {
      const { value: title } = form.fields.name;

      analyticsService.track('Clicked on Create Questionnaire');
      createClioQuestionnaire.mutate(
        {
          clioQuestionnaire: {
            title: title,
            description: '',
            introduction: '',
            conclusion: '',
          },
        },
        {
          onError: () => {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          },
          onSuccess: (res) => {
            history.push(`/questionnaires/${res.id}/select-documents`);
            hideModal();
          },
        },
      );
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Create interview template',
      primaryActionTitle: 'Continue →',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: '',
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleCreateQuestionnaireTemplate,
    });
  };

  const handleShare = (questionnaireId: string, totalQuestions: number) => {
    setIsShareModalOpen(true);
    setQuestionnaireId(questionnaireId);
    setTotalQuestions(totalQuestions);
    analyticsService.track('Clicked on Share Questionnaire', {
      questionnaire_id: tryConvertToNumber(questionnaireId),
      page: 'questionnaire_list_page',
    });
  };

  const handleRename = (questionnaire: Questionnaire) => {
    const updateQuestionnaireTitle = async (form: {
      fields: { name: { value: string } };
    }) => {
      const { value: title } = form.fields.name;
      updateClioQuestionnaire.mutate(
        {
          questionnaireId: `${questionnaire.id}`,
          clioQuestionnaire: { title },
        },
        {
          onError: () => {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          },
          onSuccess: () => {
            showToast(LAYOUT_TOAST_TYPES.success, {
              message: 'Inteview template title updated',
            });
            hideModal();
          },
        },
      );
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename inteview template',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: questionnaire.title,
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: updateQuestionnaireTitle,
    });
  };

  const handleDuplicate = async (originalQuestionnaire: Questionnaire) => {
    const duplicateQuestionnaire = async (form: {
      fields: { title: { value: string } };
    }) => {
      analyticsService.track('Clicked on Duplicate Questionnaire', {
        questionnaire_id: tryConvertToNumber(questionnaireId),
      });

      const { value: title } = form.fields.title;

      try {
        const response = await fetch(
          getQuestionnaireTemplateUrlById(
            orgFprint,
            `${originalQuestionnaire.id}`,
          ),
        );

        const templates: QuestionnaireTemplate[] = await response.json();

        const dupQuestionnaire = await duplicateClioQuestionnaire.mutateAsync({
          questionnaireId: `${originalQuestionnaire.id}`,
          title,
        });

        await createQuestionnaireTemplate.mutateAsync({
          orgFprint,
          questionnaireId: `${dupQuestionnaire.id}`,
          templateIds: templates.map((template) => template.template_id),
        });

        showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Interview template duplicated',
        });

        hideModal();
      } catch (e) {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: 'Something went wrong, please try again later.',
        });
      }
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Duplicate interview template',
      primaryActionTitle: 'Duplicate',
      fields: [
        {
          label: 'Name',
          id: 'title',
          type: 'text',
          defaultValue: originalQuestionnaire.title + ' (Copy)',
          validation: VALIDATION_TYPES.default,
        },
      ],
      onConfirm: duplicateQuestionnaire,
    });
  };

  const handleDelete = (questionnaire: Questionnaire) => {
    setIsWarnDeleteModalOpen(true);
    setQuestionnaireId(String(questionnaire.id));
  };

  const onConfirmDeleteQuestionnaire = () => {
    analyticsService.track('Clicked on Delete Questionnaire', {
      questionnaire_id: tryConvertToNumber(questionnaireId),
    });

    if (!questionnaireId) {
      return;
    }

    setIsWarnDeleteModalOpen(false);

    deleteClioQuestionnaire.mutate(
      { questionnaireId: `${questionnaireId}` },
      {
        onError: () => {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        },
        onSuccess: () => {
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Interview template deleted',
          });
          hideModal();
        },
      },
    );
  };

  if (isError || (!isLoading && !data)) {
    return (
      <EmptyView
        title={'Your interview templates will appear here'}
        paragraphs={[
          <>
            Guided interviews feature is unavaliable at the moment. Please try
            again later.
          </>,
        ]}
      >
        <></>
      </EmptyView>
    );
  }

  const questionnaires: Array<Questionnaire & { total_questions: number }> = [];

  data?.pages.forEach((page) =>
    page.questionnaires.forEach(
      (q: Questionnaire & { total_questions: number }) =>
        questionnaires.push(q),
    ),
  );

  const isEmpty = !isLoading && !questionnaires.length;

  return (
    <Box
      sx={{
        margin: '0px auto !important',
        maxWidth: '1080px !important',
        width: ' 100% !important',
      }}
    >
      <ShareQuestionnaireLinkModal
        page={'questionnaire_list_page'}
        questionnaireId={questionnaireId}
        open={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        totalQuestions={totalQuestions}
      />
      <DeleteQuestionnaireDialogModal
        open={isWarnDeleteModalOpen}
        questionnaireId={questionnaireId}
        onClose={() => setIsWarnDeleteModalOpen(false)}
        onConfirmDelete={onConfirmDeleteQuestionnaire}
      />
      {isEmpty ? (
        <EmptyTableMessage onCreate={onCreate} />
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            marginBottom={'10px'}
          >
            <Box></Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '8px',
                alignItems: 'flex-end',
              }}
            >
              <Button primary onClick={onCreate}>
                Create interview template
              </Button>
              <Box
                sx={{
                  display: 'inline-flex',
                  padding: '4px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <Edit
                  style={{
                    height: '16px',
                    width: '16px',
                  }}
                />
                <Typography variant="body1">
                  Let us know what you think about guided interviews.{' '}
                </Typography>
                <Typography variant="body1">
                  <Link
                    href="https://forms.gle/C1oh9zt21eorwBAKA"
                    rel="noopener noreferrer"
                    target="_blank"
                    color="#09F"
                  >
                    Share feedback
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          <QuestionnaireTableCardList
            questionnaires={questionnaires}
            handleShare={handleShare}
            handleDuplicate={handleDuplicate}
            handleRename={handleRename}
            handleDelete={handleDelete}
          />
          <LoadMoreDisplayText
            loading={isLoading || isFetchingNextPage}
            done={!hasNextPage}
            onLoadMore={fetchNextPage}
          />
        </>
      )}
    </Box>
  );
};
