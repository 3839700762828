import React from 'react';
import { Button } from '@mui/material';
import { MessageDialog } from '~/src/components/Modal/mui';
import Spinner from '~/src/components/Spinner';

interface Props {
  onClose: () => void;
}

export const MessageDialogLoading = ({ onClose }: Props) => {
  return (
    <MessageDialog
      title={'Loading...'}
      onClose={onClose}
      dialogActions={<Button onClick={onClose}>Close</Button>}
    >
      <Spinner />
    </MessageDialog>
  );
};
