import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as DocumentIcon } from './ClipboardChecked.svg';
import Button from '~/src/components/Button';
import { useLayoutContext } from '~/src/contexts';
import analyticsService from '~/src/services/analytics';
import { useCreateClioQuestionnaire } from '~/src/entities/questionnaires';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { history } from '~/src/utils/history';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { VALIDATION_TYPES } from '~/src/utils/validation';

export const EmptyTableMessage: React.FC = () => {
  const { showToast, showModal, hideModal } = useLayoutContext();
  const orgFprint = useCurrentOrgFprint();
  const createClioQuestionnaire = useCreateClioQuestionnaire(orgFprint);

  const handleCreate = () => {
    const handleCreateQuestionnaireTemplate = async (form: {
      fields: { name: { value: string } };
    }) => {
      const { value: title } = form.fields.name;

      analyticsService.track('Clicked on Create Questionnaire');
      createClioQuestionnaire.mutate(
        {
          clioQuestionnaire: {
            title: title,
            description: '',
            introduction: '',
            conclusion: '',
          },
        },
        {
          onError: () => {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          },
          onSuccess: (res) => {
            history.push(`/questionnaires/${res.id}/select-documents`);
            hideModal();
          },
        },
      );
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Create interview template',
      primaryActionTitle: 'Continue →',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: '',
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleCreateQuestionnaireTemplate,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        witdh: '100%',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          padding: '16px',
        }}
      >
        <DocumentIcon />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          gap: '8px',
          maxWidth: '392px',
        }}
      >
        <Typography variant="subheading">No live interviews yet.</Typography>
        <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
          Create an interview template to share with your clients. Once an
          interview is shared, you can review them here.
        </Typography>
        <Button primary onClick={handleCreate}>
          Create interview template
        </Button>
      </Box>
    </Box>
  );
};
