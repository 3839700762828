/* eslint-disable no-use-before-define */
import { useState, useEffect, useRef } from 'react';
import {
  PD_DELIMITER,
  setTabIndexOfPDFElementsAndFieldResetHandler,
} from '~/src/components/Webviewer/utils';
import {
  createMathFieldManager,
  getIsSumOrSubtractField,
} from '../utils/mathFieldManager';
import {
  getPdfBlob,
  DEFAULT_ELEMENT_HEIGHT,
  DEFAULT_MULTILINE_ELEMENT_HEIGHT,
  scaleFactor,
} from './useBackend.utils';
import ElementKeyTool from '../utils/ElementKeyTool';
import { InputType } from '~/src/models';

const useBackend = (
  stackSavedData,
  currentProject,
  webviewerInstance,
  setStackSavedData,
) => {
  const [attachedLoadedListener, setAttachedLoadedListener] = useState(false);
  const [currentPdfBlob, setCurrentPdfBlob] = useState(null);

  const pdfBlobUpdateInProgress = useRef(null);
  const destroyTabIndexListener = useRef(null);

  const fieldElementMap = useRef(new WeakMap());
  const elementFieldMap = useRef({});
  const mathFieldManager = useRef(null);

  const setCustomFieldsEventListener = function (currentProject, instance) {
    const annotManager = instance.docViewer.getAnnotationManager();
    annotManager.addEventListener(
      'annotationSelected',
      async (annotations, action) => {
        const allAnnot = await annotManager.getAnnotationsList();
        if (action === 'selected') {
          // disable pointer events on all elements so custom field can be draggable
          allAnnot.forEach((annotation) => {
            if (
              annotation.Subject == 'Widget' &&
              instance.iframeWindow.document.getElementById(
                annotation.fieldName,
              )
            ) {
              instance.iframeWindow.document.getElementById(
                annotation.fieldName,
              ).children[0].style.pointerEvents = 'none';
            }
          });
        } else if (action === 'deselected') {
          // enable pointer events on all elements so elements can be usable again
          allAnnot.forEach((annotation) => {
            if (
              annotation.Subject == 'Widget' &&
              instance.iframeWindow.document.getElementById(
                annotation.fieldName,
              )
            ) {
              instance.iframeWindow.document.getElementById(
                annotation.fieldName,
              ).children[0].style.pointerEvents = 'auto';
            }
          });
        }
      },
    );
    annotManager.addEventListener(
      'annotationChanged',
      async (annotations, action) => {
        setAttachedLoadedListener(true);
        const instance = webviewerInstance;
        if (action === 'add') {
          if (
            annotations.length === 1 &&
            annotations[0].ToolName === 'AnnotationCreateFreeText' &&
            annotations[0].getContents() === 'Insert text here'
          ) {
            annotManager.trigger('annotationChanged', [
              annotations,
              'modify',
              {},
            ]);
          }
        }
        if (action === 'modify') {
          const xfdfString = await annotManager.exportAnnotations({
            annotList: annotations,
          });
          const currentDocumentId = instance.docViewer
            .getDocument()
            .getDocumentId();
          annotations.forEach((annotation) => {
            // TODO - Improve this logic by removing annotationChanged event listener
            // Due to removing of annotationChanged event listener, there is a bug in adding custom field, so for now
            // keeping event listener and only updating if current project has document id.
            const currentProjectDocumentIds = currentProject.documents.map(
              (document) => document.id,
            );
            if (
              currentProjectDocumentIds.includes(parseInt(currentDocumentId))
            ) {
              setStackSavedData(
                `customField_${currentDocumentId}${annotation.Id}`,
                xfdfString,
              );
            }
            annotation.setRotationControlEnabled(false);
          });
          instance.docViewer.setToolMode(
            instance.docViewer.getTool('AnnotationEdit'),
          );
        } else if (action === 'delete') {
          if (!currentProject || !currentProject.documents) {
            return;
          }

          const currentDocumentId = instance.docViewer
            .getDocument()
            .getDocumentId();
          annotations.forEach((annotation) => {
            const currentProjectDocumentIds = currentProject.documents.map(
              (document) => document.id,
            );
            if (
              currentProjectDocumentIds.includes(parseInt(currentDocumentId))
            ) {
              setStackSavedData(
                `customField_${currentDocumentId}${annotation.Id}`,
                null,
              );
            }
          });
        }
      },
    );
  };
  const deleteRawPdfFormElements = function (viewer, instance) {
    viewer.addEventListener('annotationsLoaded', async () => {
      setAttachedLoadedListener(true);
      const annotManager = instance.Core.annotationManager;
      const annots = annotManager.getAnnotationsList();
      annots.forEach((widgetAnnot) => {
        if (
          stackSavedData &&
          widgetAnnot.Subject == 'Widget' &&
          widgetAnnot.getField().name in stackSavedData
        ) {
          widgetAnnot.value = stackSavedData[widgetAnnot.getField().name];
          annotManager.redrawAnnotation(widgetAnnot);
        }
      });
      const toDelete = annots.filter((a) => {
        if (
          a.Subject == 'Widget' &&
          (a.getField().name === 'NoticeHeader1' ||
            a.getField().name === 'NoticeFooter1')
        ) {
          return true;
        }
        if (
          a.Subject == 'Widget' &&
          !a.getField().name.includes('lawyaw_prefix') &&
          a.getField().tooltipName &&
          a.getField().tooltipName.toLowerCase().includes('this form')
        ) {
          return true;
        }
        return false;
      });

      try {
        annotManager.deleteAnnotations(toDelete);
      } catch (error) {
        console.error('Unable to delete some raw elements: ', error);
      }
      setCurrentPdfBlob(await getPdfBlob(instance));
    });
  };

  const addCustomFieldsFromXFDF = async (instance) => {
    if (stackSavedData) {
      Object.entries(stackSavedData).forEach(async ([key, value]) => {
        const currentDocumentId = instance.docViewer
          .getDocument()
          .getDocumentId();
        if (key.includes(`customField_${currentDocumentId}`)) {
          const annotationManager = instance.annotManager;
          await annotationManager.importAnnotations(value);
        }
      });
    }
  };

  useEffect(() => {
    const manager = mathFieldManager.current;
    return () => {
      manager?.destroy();
      webviewerInstance?.docViewer.removeEventListener('annotationsLoaded');
      webviewerInstance?.docViewer.getAnnotationManager().off('fieldChanged');
      setAttachedLoadedListener(false);
      destroyTabIndexListener.current?.();
    };
    // We only want to clean things up when the component is being unmounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPdfBlob && pdfBlobUpdateInProgress.current) {
      pdfBlobUpdateInProgress.current = false;
    }
  }, [currentPdfBlob]);

  const setFieldChangedListener = (instance) => {
    const annotManager = instance.docViewer.getAnnotationManager();
    annotManager.on('fieldChanged', async (field, value) => {
      setAttachedLoadedListener(true);
      pdfBlobUpdateInProgress.current = true;
      const fid = field.name
        .split('\t')[0]
        .substring(
          field.name.indexOf('lawyaw_prefix') + 'lawyaw_prefix'.length,
        );
      const fieldWidget = field.widgets[field.widgets.length - 1];
      const currentDocumentId = instance.docViewer
        .getDocument()
        .getDocumentId();
      const customElementId = fieldWidget.getCustomData('elementId');
      const fieldManager = annotManager.getFieldManager();
      const projectDocumentIDString = PD_DELIMITER + currentDocumentId;

      if (fid.indexOf('untagged_') < 0) {
        if (stackSavedData[fid] != value) {
          const newFieldName = `lawyaw_prefix${fid}\t${customElementId}${projectDocumentIDString}`;
          const needsUpdating = field.name !== newFieldName;
          if (needsUpdating) fieldManager.updateFieldName(field, newFieldName);
        }
      }
      let fieldKey = '';

      fieldKey = `${fid}\t${customElementId}${projectDocumentIDString}`;

      if (fieldKey.indexOf('untagged_') >= 0) {
        // untagged, add untagged_ prefix
        fieldKey = fid + projectDocumentIDString;
      }

      setStackSavedData(fieldKey, value);
      // currentProject.setStackSavedData(fieldKeyToDelete, null)

      const originalFieldValue = stackSavedData[fid];
      if (customElementId && originalFieldValue == value) {
        // Set value to null to trigger deletion of one way field
        value = null;
      }
      // TODO - In future, set xfdf data in the following as well:
      setCurrentPdfBlob(await getPdfBlob(instance));
    });
  };

  const setDocumentLoadedEventListener = (instance, docsData) => {
    instance.docViewer.one('documentLoaded', async () => {
      addCustomFieldsFromXFDF(instance);
      const { FitMode } = instance;
      instance.setFitMode(FitMode.FitWidth);
      const currentDocument = instance.docViewer.getDocument();
      // TODO - temp - find better solution
      if (!currentDocument.filename.includes('Docx')) {
        const currentDocumentOnlyDocsData = docsData.filter((docData) => {
          return docData.id === parseInt(currentDocument.getDocumentId());
        });
        await loadFormFields(instance, currentDocumentOnlyDocsData);
      }
      const annotManager = instance.docViewer.getAnnotationManager();
      annotManager.setReadOnly(false);
    });
  };

  const loadDocumentIntoWebViewer = (loadDocumentData) => {
    webviewerInstance.loadDocument(
      loadDocumentData.fileUrl,
      loadDocumentData.data,
    );
    if (!attachedLoadedListener) {
      setCustomFieldsEventListener(currentProject, webviewerInstance);
      setFieldChangedListener(webviewerInstance);
      deleteRawPdfFormElements(
        webviewerInstance.Core.documentViewer,
        webviewerInstance,
      );
    }
    const { docsData, currentProject } = loadDocumentData;
    setDocumentLoadedEventListener(
      webviewerInstance,
      docsData,
      loadDocumentData.isEsign,
    );
    const addCustomFieldQuerySelector =
      webviewerInstance.iframeWindow.document.querySelector(
        '[data-element="add-custom-field"]',
      );
    if (
      loadDocumentData.currentDocumentInfo &&
      loadDocumentData.currentDocumentInfo.docx
    ) {
      if (addCustomFieldQuerySelector) {
        addCustomFieldQuerySelector.style.visibility = 'hidden';
      }
    } else if (addCustomFieldQuerySelector) {
      addCustomFieldQuerySelector.style.visibility = 'visible';
    }
  };

  const newInit = (docsData) => {
    const instance = webviewerInstance;
    const currentDocument = docsData[0];
    if (currentDocument === undefined) {
      return;
    }

    if (mathFieldManager.current) {
      mathFieldManager.current.destroy();
    }

    mathFieldManager.current = createMathFieldManager(
      instance.Core.documentViewer,
      (id) => elementFieldMap.current[id],
      (field) => fieldElementMap.current.get(field),
    );

    const currentDocumentTitleArray = currentDocument.title.split('/');
    const filename =
      currentDocumentTitleArray[currentDocumentTitleArray.length - 1];
    let pdfUrl =
      currentDocument.pdf ||
      currentDocument.originalPdfBlob ||
      currentDocument.originalFileUrl;
    const addCustomFieldQuerySelector =
      webviewerInstance.iframeWindow.document.querySelector(
        '[data-element="add-custom-field"]',
      );
    if (currentDocument.docx) {
      pdfUrl = currentDocument.ms_pdf;
      if (addCustomFieldQuerySelector) {
        addCustomFieldQuerySelector.style.visibility = 'hidden';
      }
    } else if (addCustomFieldQuerySelector) {
      addCustomFieldQuerySelector.style.visibility = 'visible';
    }

    instance.loadDocument(pdfUrl, {
      filename,
      documentId: `${currentDocument.id}`,
      extension: 'pdf',
    });
    const { destroy } = setTabIndexOfPDFElementsAndFieldResetHandler(
      instance,
      (fieldToReset) => {
        setStackSavedData(fieldToReset, null);
      },
      (annotation) => annotation.getCustomData('originalValue'),
      (annotation) => JSON.parse(annotation.getCustomData('idForTabIndex')),
      (annotation, newValue) => {
        annotation.setCustomData('oneWayField', JSON.stringify(newValue));
      },
      (annotation) => {
        const oneWay = annotation.getCustomData('oneWayField');
        return oneWay && JSON.parse(oneWay);
      },
    );
    destroyTabIndexListener.current = destroy;
    setCustomFieldsEventListener(currentProject, instance);
    setFieldChangedListener(instance);
    deleteRawPdfFormElements(instance.Core.documentViewer, instance);
    setDocumentLoadedEventListener(instance, docsData);
  };

  const loadFormFields = async function (instance, docsData) {
    docsData.forEach((docData) => {
      if (docData.allElements) {
        let tabIndex = 1000;
        docData.allElements.forEach((elementData) => {
          // addFormField is a function for adding form fields when loading pdfs in prox, not used for standlone esign.
          // addFormField(instance, elementData, page.pageNumber || page.page)
          addFormField(instance, elementData, elementData.pageNumber, tabIndex);
          tabIndex += 1;
        });
      }
    });
  };

  const addFormField = function (instance, elementData, pageNumber, tabIndex) {
    const { Annotations, annotManager } = instance;
    const currentDocumentId = instance.docViewer.getDocument().getDocumentId();
    const projectDocumentIDString =
      ElementKeyTool.formatProjectDocumentIdString(
        PD_DELIMITER,
        currentDocumentId,
      );

    const widgetFlags = new Annotations.WidgetFlags();
    widgetFlags.set('Edit', true);
    let fieldType = 'Tx';
    let multilineHeight = null;
    let fieldValue = elementData.value;
    if (elementData.inputType == 2) {
      fieldType = 'Btn';
      fieldValue = elementData.value === 'Yes' ? 'On' : 'Off';
    }
    if (elementData.inputType == 4) {
      widgetFlags.set('Multiline', true);
      widgetFlags.set('DoNotScroll', true);
      multilineHeight = true;
    }

    const computedFieldType = false;
    const customFieldComputedTag = false;
    const computedTagDestination = false;
    let oneWayField = false;
    let fieldKey = ElementKeyTool.formatUntaggedElement(elementData);
    let fieldName = ElementKeyTool.formatFieldName(elementData, fieldKey);

    if (stackSavedData) {
      // There is stack saved data to resolve data from.

      // #1 - The first item in priority is the (untagged element:project document) tag.
      // #2 - Then we check for the (untagged element) tag.
      // #3 - Last we check for the tagged elements.
      // #3.1 - For tagged elements, we delegate to the ElementKeyTool to resolve the tag value.
      const untaggedElProjectDocumentKey = `${fieldKey}${projectDocumentIDString}`;
      if (untaggedElProjectDocumentKey in stackSavedData) {
        fieldValue = stackSavedData[untaggedElProjectDocumentKey];
      } else if (fieldKey in stackSavedData) {
        fieldValue = stackSavedData[`untagged_${elementData.id}`];
      } else if (elementData.tag) {
        const retVal = ElementKeyTool.tryResolveTagValue(
          elementData,
          stackSavedData,
          projectDocumentIDString,
        );
        oneWayField = retVal['oneWayField'];
        fieldValue = retVal['tagValue'];
      }
    }

    if (getIsSumOrSubtractField(elementData.computedFieldType)) {
      oneWayField = false;
      widgetFlags.set('ReadOnly', true);
    }

    // Replace null character (\0) so that pdfs load properly and can be downloaded
    fieldValue = fieldValue?.replace(/\0/g, '');

    // TODO - Potentially use pdftrons datepicker.
    // if (elementData.tag == "lawyaw.client.dob") {
    //   fieldType = 'Tx'
    //   fieldValue = '09/09/2020'
    // }
    const field = new Annotations.Forms.Field(fieldName, {
      type: fieldType,
      value: fieldValue,
      flags: widgetFlags,
    });

    let widgetAnnot = new Annotations.TextWidgetAnnotation(field);
    if (elementData.inputType == 2) {
      widgetAnnot = new Annotations.CheckButtonWidgetAnnotation(field, {
        appearance: 'Off',
        appearances: {
          Off: {},
          Yes: {},
        },
      });
    }

    // TODO - Potentially use pdftrons datepicker.
    // if (elementData.tag == "lawyaw.client.dob") {
    //   widgetAnnot = new Annotations.DatePickerWidgetAnnotation(field, {});
    // }

    widgetAnnot.NoResize = true;
    widgetAnnot.NoMove = false;
    widgetAnnot.StrokeThickness = 0;
    widgetAnnot.FillColor = new Annotations.Color(...[0, 255, 255]);
    widgetAnnot.TextColor = new Annotations.Color(...[0, 0, 0]);

    // For ALL text single line text fields
    // we are forcing the height of the element to be 12, so its consistent
    // throughout the forms, this makes the default font size consistent,
    // also allows auto shrink to be enabled as when autoshrink is enabled, we
    // can't overwride the default font manually, the default font size fits the height
    // of the element
    widgetAnnot.PageNumber = pageNumber;
    widgetAnnot.X =
      elementData.left >= 0 ? elementData.left / scaleFactor : 150;
    widgetAnnot.Y = elementData.top >= 0 ? elementData.top / scaleFactor : 200;
    widgetAnnot.Width = elementData.width / scaleFactor || 150;
    if (elementData.inputType == InputType.Textbox) {
      widgetAnnot.Height = (DEFAULT_ELEMENT_HEIGHT / scaleFactor) * 1.05;
    } else if (elementData.inputType == InputType.TextArea) {
      widgetAnnot.Height = (elementData.height / scaleFactor) * 1.05;
    } else {
      widgetAnnot.Height = (elementData.height / scaleFactor) * 0.95;
    }
    // For multiline fields, setting custom font to disable autoshrink.
    // Enabling autoshrink sets the font size to fit the height of the input
    // which results in a large font for multiline fields
    if (multilineHeight) {
      widgetAnnot.font = new Annotations.Font({
        size: DEFAULT_ELEMENT_HEIGHT / scaleFactor,
        name: 'Lato',
      });
    } else {
      widgetAnnot.font = new Annotations.Font({
        size: 0,
        name: 'Lato',
      });
    }
    widgetAnnot.setCustomData(
      'idForTabIndex',
      JSON.stringify(tabIndex + 1000 * (elementData.pageNumber - 1)),
    );
    widgetAnnot.setCustomData('originalValue', stackSavedData[elementData.tag]);
    widgetAnnot.setCustomData('elementId', elementData.id);
    widgetAnnot.setCustomData('elementTag', elementData.tag);
    widgetAnnot.setCustomData('elementAliasTag', elementData.aliasTag);
    widgetAnnot.setCustomData('multiline', multilineHeight);
    widgetAnnot.setCustomData(
      'lineHeight',
      (elementData.lineHeight || DEFAULT_MULTILINE_ELEMENT_HEIGHT) *
        scaleFactor,
    );
    widgetAnnot.setCustomData('widgetAnnotOriginalHeight', widgetAnnot.Height);
    widgetAnnot.setCustomData('oneWayField', JSON.stringify(oneWayField));
    widgetAnnot.setCustomData(
      'computedFieldType',
      JSON.stringify(computedFieldType),
    );
    widgetAnnot.setCustomData(
      'computedFieldTag',
      JSON.stringify(customFieldComputedTag),
    );
    widgetAnnot.setCustomData(
      'computedTagDestination',
      JSON.stringify(computedTagDestination),
    );
    // add the form field and widget annotation
    // Highlight one way fields differently, TODO - change background as field is updated
    Annotations.WidgetAnnotation.getCustomStyles = (widget) => {
      if (widget instanceof Annotations.TextWidgetAnnotation) {
        const customCss = {
          background: 'rgba(73,182,239,.14)',
          color: 'rgb(0, 83, 175)',
          border: '0px',
        };
        if (
          widget.getCustomData('oneWayField') &&
          JSON.parse(widget.getCustomData('oneWayField'))
        ) {
          customCss.background = 'rgba(113,113,113,.1)';
        }
        // The following sets the line height within the annotation
        // rendered by pdftron
        // BUT the main issue is that this css is not recognized by
        // PDFs and the line height is not retained by downloaded PDF
        // As a fix, a custom annotations + canvas is drawn with the same line height
        const isMultiLine =
          widget.getCustomData('multiline') &&
          JSON.parse(widget.getCustomData('multiline'));
        const elementLineHeight =
          widget.getCustomData('lineHeight') &&
          JSON.parse(widget.getCustomData('lineHeight'));
        if (isMultiLine && elementLineHeight) {
          customCss['line-height'] = `${(
            (elementLineHeight / DEFAULT_ELEMENT_HEIGHT / scaleFactor) *
            100
          ).toFixed(2)}%`;

          /*
          fix: unable to type the 2nd line in multiple-line field in webviewer8.12.3
          https://github.com/mystacksco/issues/issues/1426
          */
          customCss['padding-top'] = '0px';
        }
        return customCss;
      }
    };

    Annotations.WidgetAnnotation.getContainerCustomStyles = (widget) => {
      if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
        return {
          background: 'rgba(73,182,239,.14)',
        };
      }
    };
    Annotations.WidgetAnnotation.getCustomStyles(widgetAnnot);
    Annotations.WidgetAnnotation.getContainerCustomStyles(widgetAnnot);
    annotManager.addAnnotation(widgetAnnot);
    annotManager.drawAnnotationsFromList([widgetAnnot]);
    annotManager.getFieldManager().addField(field);
    annotManager.redrawAnnotation(widgetAnnot);

    fieldElementMap.current.set(field, elementData);
    elementFieldMap.current[elementData.id] = field;
    if (getIsSumOrSubtractField(elementData.computedFieldType))
      mathFieldManager.current.addComputedElement(elementData);
  };

  return {
    newInit,
    loadDocumentIntoWebViewer,
    currentPdfBlob,
    pdfBlobUpdateInProgress: pdfBlobUpdateInProgress.current,
  };
};

export default useBackend;
