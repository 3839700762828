export const DEFAULT_ELEMENT_HEIGHT = 14;
export const DEFAULT_MULTILINE_ELEMENT_HEIGHT = 18;
export const scaleFactor = 1.333;

function wrapText(context, text, x, y, line_width, line_height) {
  let line = '';
  const paragraphs = (text || '').split('\n');
  for (let i = 0; i < paragraphs.length; i++) {
    if (i == 0) {
      y += 3;
    }
    const words = paragraphs[i].split(' ');
    for (let n = 0; n < words.length; n++) {
      const testLine = `${line + words[n]} `;
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > line_width && n > 0) {
        context.fillText(line, x, y);
        line = `${words[n]} `;
        y += line_height;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
    y += line_height;
    line = '';
  }
}

export const getFileFromUrl = async (url, name) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type:
      response.headers.get('content-type') ||
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });
};

export const getPdfBlob = async function (
  webviewerInstance,
  downloadOnly = false,
) {
  const instanceViewer = webviewerInstance.docViewer;
  const doc = instanceViewer.getDocument();
  const annotManager = instanceViewer.getAnnotationManager();
  const annots = annotManager.getAnnotationsList();
  const mustDeleteMultilineInputs = [];
  const printOnlyMultilineInputs = [];
  // When downloading, create a custom multiline annotation that draws a canvas
  // and manually implements line height for given text. THe breaking up of text
  // as per its length has to be done manually as well to fit within the width.
  if (downloadOnly) {
    annots.forEach(async (widgetAnnot) => {
      if (
        widgetAnnot.Subject == 'Widget' &&
        widgetAnnot.getField().flags.get('Multiline') &&
        widgetAnnot.getCustomData('lineHeight')
      ) {
        const { Annotations } = webviewerInstance;
        const currentFieldName = widgetAnnot.getField().name;
        const multilineWidgetExists = annots.filter(
          (a) => a.getCustomData('originalFieldName') === currentFieldName,
        );
        if (multilineWidgetExists.length > 0) {
          multilineWidgetExists[0].Height = JSON.parse(
            multilineWidgetExists[0].getCustomData('widgetAnnotOriginalHeight'),
          );
          multilineWidgetExists[0].textToSet = widgetAnnot.getField().value;
          annotManager.redrawAnnotation(multilineWidgetExists[0]);
          printOnlyMultilineInputs.push(multilineWidgetExists[0]);
          mustDeleteMultilineInputs.push(widgetAnnot);
          widgetAnnot.Height = 0;
        } else {
          class MultilineAnnotation extends Annotations.CustomAnnotation {
            constructor() {
              super('multilineannot'); // provide the custom XFDF element name
              this.Subject = 'CustomMultiLineCanvas';
            }

            draw(ctx) {
              const x = this.X;
              const y = this.Y;
              const maxWidth = this.Width;

              const text = this.textToSet;
              const { lineHeight } = this;
              ctx.font = `${DEFAULT_ELEMENT_HEIGHT / scaleFactor}px Lato`;
              ctx.textBaseline = 'top';
              wrapText(ctx, text, x, y, maxWidth + 1, lineHeight);
            }
          }

          MultilineAnnotation.prototype.elementName = 'multilineannot';
          annotManager.registerAnnotationType(
            MultilineAnnotation.prototype.elementName,
            MultilineAnnotation,
          );
          const customMultilinePrintOnlyAnnot = new MultilineAnnotation();
          customMultilinePrintOnlyAnnot.PageNumber =
            widgetAnnot.getPageNumber();
          customMultilinePrintOnlyAnnot.X = widgetAnnot.getX();
          customMultilinePrintOnlyAnnot.Y = widgetAnnot.getY();
          customMultilinePrintOnlyAnnot.setCustomData(
            'originalFieldName',
            currentFieldName,
          );
          customMultilinePrintOnlyAnnot.Width = widgetAnnot.getWidth();
          customMultilinePrintOnlyAnnot.Height = widgetAnnot.getHeight() * 1.1;
          customMultilinePrintOnlyAnnot.setCustomData(
            'widgetAnnotOriginalHeight',
            widgetAnnot.getHeight(),
          );
          customMultilinePrintOnlyAnnot.textToSet =
            widgetAnnot.getField().value;
          customMultilinePrintOnlyAnnot.lineHeight =
            JSON.parse(widgetAnnot.getCustomData('lineHeight')) /
            (scaleFactor * 1.32);
          annotManager.addAnnotation(customMultilinePrintOnlyAnnot);
          printOnlyMultilineInputs.push(customMultilinePrintOnlyAnnot);

          mustDeleteMultilineInputs.push(widgetAnnot);
          widgetAnnot.Height = 0;
        }
      }
    });
  }

  const xfdfString = await annotManager.exportAnnotations();
  const options = { xfdfString, flatten: true };
  const data = await doc.getFileData(options);
  const arr = new Uint8Array(data);
  const blob = new Blob([arr], { type: 'application/pdf' });
  // When downloading draw and redraw the appropriate custom annotations
  // as the multiline annotaitons that are shown on downloaded pdf are different
  // from the one rendered in pdfviewer.
  if (downloadOnly) {
    printOnlyMultilineInputs.forEach((annot) => {
      annot.textToSet = '';
      annotManager.redrawAnnotation(annot);
    });
    mustDeleteMultilineInputs.forEach((annot) => {
      annot.Height = JSON.parse(
        annot.getCustomData('widgetAnnotOriginalHeight'),
      );
    });
  }
  return {
    blobData: blob,
    numberOfPages: doc.getPageCount(),
    documentId: +doc.getDocumentId(),
  };
};
