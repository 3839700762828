import React, { useCallback, useContext, useState } from 'react';

import { ModalType } from '../components/PageLayout/Modals';
import { SlideInType } from '../components/PageLayout/SlideIns';
import { ToastType } from '../components/PageLayout/Toasts';

export const LAYOUT_FOOTER_TYPES = {
  default: 'DEFAULT',
  questionnaireResponsePage: 'QUESTIONNAIRE_RESPONSE_PAGE',
  templateDefaultValuesDraftingFooter:
    'TEMPLATE_DEFAULT_VALUES_DRFATING_FOOTER',
  action: 'ACTION',
} as const;

type LayoutFooterType =
  typeof LAYOUT_FOOTER_TYPES[keyof typeof LAYOUT_FOOTER_TYPES];

declare global {
  interface Window {
    Intercom: any;
  }
}

const raiseIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update', {
      vertical_padding: 80,
    });
  }
};

const lowerIntercom = () => {
  if (window.Intercom) {
    window.Intercom('update', {
      vertical_padding: 0,
    });
  }
};

export interface ILayoutContext {
  title: string;
  setTitle: (title: string) => void;

  toastActive: boolean;
  toastType: ToastType | null;
  toastProps: Record<string, any>;
  showToast: (type: ToastType, props: Record<string, any>) => void;
  hideToast: () => void;

  modalActive: boolean;
  modalType: ModalType | null;
  modalProps: Record<string, any>;
  showModal: (type: ModalType, props: Record<string, any>) => void;
  hideModal: () => void;

  footerActive: boolean;
  footer: LayoutFooterType | null;
  footerProps: any;
  showFooter: (type: LayoutFooterType, props: Record<string, any>) => void;
  hideFooter: () => void;

  slideIn: SlideInType | null;
  slideInActive: boolean;
  slideInProps: Record<string, any>;
  showSlideIn: (type: SlideInType, props: Record<string, any>) => void;
  hideSlideIn: () => void;

  isRefactorDisclaimerBannerClosed: boolean;
  hideRefactorDisclaimerBanner: () => void;
}

export const LayoutContext = React.createContext<ILayoutContext | null>(null);
export const useLayoutContext = () =>
  useContext(LayoutContext) as ILayoutContext;

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState('');

  /* Modal State */
  const [modalActive, setModalActive] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [modalType, setModalType] = useState<ModalType | null>(null);

  const showModal = useCallback((modalTypeToShow: ModalType, props = {}) => {
    setModalType(modalTypeToShow);
    setModalProps(props);
    setModalActive(true);
  }, []);

  const hideModal = () => {
    setModalActive(false);
    // setModalType(null);
    setModalProps({});
  };

  /* Toast State */
  const [toastActive, setToastActive] = useState(false);
  const [toastProps, setToastProps] = useState({});
  const [toastType, setToastType] = useState<ToastType | null>(null);

  const showToast = (toastTypeToShow: ToastType, props = {}) => {
    raiseIntercom();
    setToastType(toastTypeToShow);
    setToastProps(props);
    setToastActive(true);
  };

  const hideToast = () => {
    lowerIntercom();
    setToastActive(false);
    // setModalType(null);
    // setToastProps({});
  };

  /* Slide In State */
  const [slideInActive, setSlideInActive] = useState(false);
  const [slideInProps, setSlideInProps] = useState({});
  const [slideIn, setSlideIn] = useState<SlideInType | null>(null);
  const [
    isRefactorDisclaimerBannerClosed,
    setIsRefactorDisclaimerBannerClosed,
  ] = useState(false);

  const hideRefactorDisclaimerBanner = () => {
    setIsRefactorDisclaimerBannerClosed(true);
  };

  const showSlideIn = (slideIn: SlideInType, props = {}) => {
    raiseIntercom();
    setSlideInProps(props);
    setSlideIn(slideIn);
    setSlideInActive(true);
  };

  const hideSlideIn = () => {
    lowerIntercom();
    setSlideInActive(false);
    setTimeout(() => {
      if (!slideInActive) {
        setSlideInProps({});
      }
    }, 500);
    setSlideIn(null);
  };

  /* Footer State */
  const [footerActive, setFooterActive] = useState(false);
  const [footer, setFooter] = useState<LayoutFooterType | null>(null);
  const [footerProps, setFooterProps] = useState({});

  const showFooter = (footerType: LayoutFooterType, footerProps = {}) => {
    raiseIntercom();

    if (footerType) {
      setFooter(footerType);
      setFooterProps(footerProps);
    }

    setFooterActive(true);
  };

  const hideFooter = () => {
    lowerIntercom();

    setFooterActive(false);
    setFooter(LAYOUT_FOOTER_TYPES.default);
    setFooterProps({});
  };

  return (
    <LayoutContext.Provider
      value={{
        title,
        setTitle,

        /* Slide In Props */
        slideIn,
        slideInProps,
        slideInActive,
        showSlideIn,
        hideSlideIn,
        /* Footer Props */
        showFooter,
        hideFooter,
        footerActive,
        footer,
        footerProps,
        /* Modal Props */
        showModal,
        hideModal,
        modalType,
        modalActive,
        modalProps,
        /* Toast Props */
        showToast,
        hideToast,
        toastType,
        toastActive,
        toastProps,
        isRefactorDisclaimerBannerClosed,
        hideRefactorDisclaimerBanner,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
