import { ALL_COURT_FORMS_FLAGS, FeatureAccessType } from '../featureFlags';
import { useCurrentOrg, getOrg } from './useCurrentOrg';
import { stateCodeToStateName } from '~/src/utils/stateCodeToStateName';
import { queryClient } from '~/src/utils/queryClient';
import { Organization } from './useMemberships';

export type StateCode = keyof typeof stateCodeToStateName;

export const canOrgUseFeatureFlag = (
  flag: FeatureAccessType,
  org: Organization | undefined,
) => org?.feature_accesses[flag]?.can_use_feature;

export const useFeatureFlag = (flag: FeatureAccessType) =>
  canOrgUseFeatureFlag(flag, useCurrentOrg().org);

export const useCheckFeatureFlag = () => {
  const org = useCurrentOrg().org!;
  return (flag: FeatureAccessType) => canOrgUseFeatureFlag(flag, org);
};

export const useQuestionnairesEnabled = () => useFeatureFlag('INTAKE_FORMS');

export const useClioQuestionnairesEnabled = () =>
  useFeatureFlag('QUESTIONNAIRES');

export const useWordDocumentAutomationEnabled = () =>
  useFeatureFlag('WORD_DOCUMENT_AUTOMATION');

export const useCourtFormsEnabled = () =>
  ALL_COURT_FORMS_FLAGS.some(useCheckFeatureFlag());

export const useFederalCourtFormsEnabled = () =>
  useFeatureFlag('FEDERAL_FORMS');

export const useIsUnifiedPlatform = () => useFeatureFlag('TAGS_V2');

export const useSubscriptionV2Enabled = () =>
  useFeatureFlag('SUBSCRIPTIONS_V2');

export const useIsClioLead = () => useFeatureFlag('MANAGE_LEAD');

export const useIsClioFree = () => useFeatureFlag('CLIO_FREE');

export const useSingleMatterImportEnabled = () =>
  useFeatureFlag('SINGLE_MATTER_IMPORT');

export const useTemplateDefaultValuesEnabled = () =>
  useFeatureFlag('TEMPLATE_DEFAULT_VALUES');

export const useCheckStateFormsEnabled = () => {
  const check = useCheckFeatureFlag();
  return (stateCode: StateCode) =>
    check(`${stateCode}_JC_FORMS` as FeatureAccessType);
};

export const isFeatureFlagEnabled = (
  flag: FeatureAccessType,
  client = queryClient,
) => canOrgUseFeatureFlag(flag, getOrg(client));

export const useIsCanary = (): boolean =>
  useFeatureFlag('DRAFT_SIGNAL_SUPPORT') === true;
