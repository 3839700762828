/* Libraries */
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

/* Hooks */
import useFeatureFlags from '~/src/hooks/useFeatureFlags';

/* Components */
import SignatureDraftContainer from '~/src/containers/SignatureDraftContainer';
import EsignaturesGated from '~/src/components/EsignaturesGated';
import CustomSignaturePackageContainer from '~/src/containers/CustomSignaturePackageContainer';
import { useLayoutContext } from '~/src/contexts/Layout';

const SignatureRoutes = ({ showModal, hideModal }) => {
  return (
    <Switch>
      <Route
        path={[
          '/signatures/:projectId/:packageId/new',
          '/signature-wizard/:projectId/:packageId/new',
        ]}
        render={({ match: { params } }) => {
          return (
            <SignatureDraftContainer
              projectId={params.projectId}
              signaturePackageId={params.packageId}
              showModal={showModal}
              hideModal={hideModal}
              step="select-documents"
            />
          );
        }}
      />
      <Route
        path="/signature-wizard/:projectId/:packageId/:step"
        render={({ match: { params } }) => {
          return (
            <SignatureDraftContainer
              projectId={params.projectId}
              signaturePackageId={params.packageId}
              showModal={showModal}
              hideModal={hideModal}
              step={params.step}
            />
          );
        }}
      />
      <Route
        path="/custom-signature-package/new"
        render={() => {
          return (
            <CustomSignaturePackageContainer
              step="select-documents"
              showModal={showModal}
              hideModal={hideModal}
            />
          );
        }}
      />
      <Route
        path="/custom-signature-package/:projectId/:packageId/:step"
        render={({ match: { params } }) => {
          return (
            <CustomSignaturePackageContainer
              step={params.step}
              maybeProjectId={params.projectId}
              maybeSignaturePackageId={params.packageId}
              showModal={showModal}
              hideModal={hideModal}
            />
          );
        }}
      />
    </Switch>
  );
};

const SignatureRoutesContainer = () => {
  const { showModal, hideModal, setTitle } = useLayoutContext();

  const { isClioFreePlan } = useFeatureFlags();

  useEffect(() => {
    setTitle('Signatures');
  }, [setTitle]);

  if (isClioFreePlan) {
    return <EsignaturesGated />;
  }

  return <SignatureRoutes showModal={showModal} hideModal={hideModal} />;
};

export default SignatureRoutesContainer;
