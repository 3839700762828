import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { AlertTriangle } from 'react-feather';

export const IsDirtyMessage: React.FC = () => {
  return (
    <Alert
      sx={(theme) => ({
        padding: theme.spacing(0.5, 2),
        background: theme.palette.yellow[50],
        color: theme.palette.text.primary,
        '.MuiAlert-icon': {
          color: theme.palette.text.primary,
        },
      })}
      icon={<AlertTriangle fontSize="inherit" />}
      severity="warning"
    >
      <AlertTitle>Updates detected</AlertTitle>
      You have previously generated a link for this matter and have made changes
      to the responses submitted by the recipient. If you want the recipient to
      see the updated responses in the interview template, delete the existing
      interview template to generate a new link. All other submitted responses
      will not be lost.
    </Alert>
  );
};
