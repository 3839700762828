import React from 'react';
import { AlertTriangle, X } from 'react-feather';
import { useLayoutContext } from '~/src/contexts/Layout';
import useFeatureFlags from '~/src/hooks/useFeatureFlags';

/*
To customize the contents and behavior of this banner, 
1. set the two displayBannerIf variables to intended behavior
2. set the two messages to intended contents 
*/
const RefactorDisclaimerBannerContainer = () => {
  const { isCanary } = useFeatureFlags();
  const { hideRefactorDisclaimerBanner, isRefactorDisclaimerBannerClosed } =
    useLayoutContext();

  const displayBannerIfNonCanary = false;
  const displayBannerIfCanary = false;

  const displayBanner =
    (isCanary && displayBannerIfCanary) ||
    (displayBannerIfNonCanary && !isCanary);

  if (isRefactorDisclaimerBannerClosed || !displayBanner) {
    return null;
  }

  const canaryMessage = (
    <p className="text-sm">
      Clio Draft will be undergoing scheduled maintenance on June 19th 2024
      between 11:00 PM - 12:00 AM PDT, at which time it will be temporarily
      unavailable.<br></br>
      Thank you for your continued understanding.
    </p>
  );
  const nonCanaryMessage = (
    <p className="text-sm">
      Clio Draft will be undergoing scheduled maintenance on June 19th 2024
      between 11:00 PM - 12:00 AM PDT, at which time it will be temporarily
      unavailable.<br></br>
      Thank you in advance for your understanding.
    </p>
  );
  const message = isCanary ? canaryMessage : nonCanaryMessage;

  return (
    <div className="flex justify-between p-4 bg-blue-100">
      <div className="flex">
        <div className="flex items-center justify-center mr-5">
          <AlertTriangle size="25px" />
        </div>
        {message}
      </div>
      <div className="mr-2 ml-auto flex items-center justify-center">
        <X
          onClick={() => {
            hideRefactorDisclaimerBanner();
          }}
          size={'20px'}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default RefactorDisclaimerBannerContainer;
