import * as React from 'react';
import { LoadingOverlay } from '~/src/components/PageLayout';
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import useFeatureFlags from '~/src/hooks/useFeatureFlags';
import useMst from '~/src/hooks/useMst';

import { renderFormField } from '~/src/components/Forms';
import MatterDetail from './MatterDetail';
import { useLayoutContext } from '../../contexts/Layout';

export const MatterDetailsPageContent = ({
  matter,
  clioQuestionnaireSubmissions,
  clioQuestionnaireSubmissionUnmappedQuestions,
  isFetching,
  isError,
  refetch,
}) => {
  const { showSlideIn } = useLayoutContext();
  const { isQuestionnairesEnabled, isClioQuestionnaireEnabled } =
    useFeatureFlags();

  const { viewContact } = useMst((store) => ({
    viewContact: store.contacts.viewContact,
  }));

  const showContact = (contact) => {
    viewContact(contact.id);
    showSlideIn(LAYOUT_SLIDEIN_TYPES.contact, {
      edit: false,
      id: contact.id,
      matterId: matter?.id,
    });
  };

  if (isFetching)
    return <LoadingOverlay title="Loading matter" subtitle="Please wait" />;

  if (isError) return <div>Error fetching matter.</div>;

  const summaryFields = matter
    .summaryFields()
    .map((field) => renderFormField({ ...field, editable: false }));

  const relatedContactsFields = matter
    .relatedContactsFields()
    .map((field) =>
      renderFormField(
        { ...field, editable: false },
        { onClick: () => showContact(field.contact) },
      ),
    );

  const { documents } = matter.details;
  const { documentSets } = matter.details;
  const intakeResponses = matter.details.questionnaireResponses;

  const entityCardFields = matter.getEntityCardFields();
  const mappedEntityCardFields = {};
  let clioCustomFields = [];

  Object.keys(entityCardFields).forEach((key) => {
    const mappedFields = entityCardFields[key].map((field) =>
      renderFormField({ ...field, editable: false }),
    );

    if (key === 'clio_matter') {
      clioCustomFields = mappedFields.filter(
        (mappedField) => mappedField.id !== 'number',
      );
    } else {
      mappedEntityCardFields[key] = mappedFields;
    }
  });

  return (
    <MatterDetail
      onSynced={refetch}
      summaryFields={summaryFields}
      documents={documents}
      documentSets={documentSets}
      intakes={intakeResponses}
      relatedContactsFields={relatedContactsFields}
      clioCustomFields={clioCustomFields}
      entityCardFields={mappedEntityCardFields}
      isQuestionnairesEnabled={isQuestionnairesEnabled}
      matter={matter}
      isClioQuestionnaireEnabled={isClioQuestionnaireEnabled}
      clioQuestionnaireSubmissions={clioQuestionnaireSubmissions}
      clioQuestionnaireSubmissionUnmappedQuestions={
        clioQuestionnaireSubmissionUnmappedQuestions
      }
    />
  );
};
