import moment from 'moment';
import { useSubscription } from '../entities/subscription';
import { useCurrentOrg, useFeatureFlag } from '../entities/user';

export const useNeedsToCreateSubscription = () => {
  const { org } = useCurrentOrg();
  const { data: subscription } = useSubscription();
  const isManageLead = useFeatureFlag('MANAGE_LEAD');

  if (isManageLead) return false;

  if (
    subscription &&
    'is_active_free_trial' in subscription &&
    subscription.is_active_free_trial
  )
    return moment().isAfter(subscription.trial_end);

  if (org?.is_non_profit) return false;

  return !subscription;
};
