import React, { FC } from 'react';

/* Components */
import CourtFormsLandingEmptyView from '~/src/components/CourtForms/CourtFormsLandingEmptyView';
import FederalSection from '~/src/components/CourtForms/FederalSection';
import StateSection from '~/src/components/CourtForms/StateSection';
import { stateCodeToStateName } from '~/src/utils/stateCodeToStateName';
import { DrillDownPanelButton } from '~/src/components/DrillDownPanel';

/* Hooks */
import useFeatureFlags from '~/src/hooks/useFeatureFlags';
import { StateCode } from '~/src/entities/user';

interface Props {
  handleStateFormsSelect: (s: keyof typeof stateCodeToStateName) => void;
  handleStateReginalFormsSelect: (s: keyof typeof stateCodeToStateName) => void;
}

const CourtFormsLandingWrapper: FC<Props> = ({
  handleStateFormsSelect,
  handleStateReginalFormsSelect,
}) => {
  const {
    isCourtFormsEnabled,
    isFederalCourtFormsEnabled,
    doesFeatureFlagForThisStateCodeExist,
  } = useFeatureFlags();

  if (!isCourtFormsEnabled) {
    return <CourtFormsLandingEmptyView />;
  }

  return (
    <div>
      {isFederalCourtFormsEnabled && (
        <FederalSection>
          <DrillDownPanelButton
            label="USCIS forms"
            handleClick={() => handleStateFormsSelect('FEDERAL')}
          />
        </FederalSection>
      )}
      {Object.entries(stateCodeToStateName)
        .filter((entry) =>
          doesFeatureFlagForThisStateCodeExist(entry[0] as StateCode),
        )
        .map(([stateCode, stateName]) => (
          <StateSection key={`${stateCode}_section`} stateName={stateName}>
            <DrillDownPanelButton
              key={`${stateCode}_form`}
              label={`${stateName} forms`}
              handleClick={() =>
                handleStateFormsSelect(
                  stateCode as keyof typeof stateCodeToStateName,
                )
              }
            />
            <DrillDownPanelButton
              key={`${stateCode}_locality`}
              label={`${stateName} regional forms`}
              handleClick={() =>
                handleStateReginalFormsSelect(
                  stateCode as keyof typeof stateCodeToStateName,
                )
              }
            />
          </StateSection>
        ))}
    </div>
  );
};

export default CourtFormsLandingWrapper;
