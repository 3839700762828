import * as React from 'react';

type SelectionSidebarHeaderProps = {
  title: string;
  count?: number;
  extra?: React.ReactNode;
};

export const SelectionSidebarHeader = ({
  title,
  count,
  extra,
}: SelectionSidebarHeaderProps) => {
  return (
    <div className="flex justify-between p-3 flex-col lg:flex-row gap-3 pb-0">
      <div className="pt-2">
        <h3>
          <span className="font-medium mr-2">{title}</span>
          <span className="text-xs rounded-full	bg-gray-200	p-1 px-2 font-medium">
            {count}
          </span>
        </h3>
      </div>
      <div>{extra}</div>
    </div>
  );
};
